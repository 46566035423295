import React, { useState, useEffect } from 'react';

const BottomMenu = ({ user, accessToken, handleLogout, handleActiveButtonClick, selectedButton, handleActiveFilterfullHeightButtonClick, selectedFilterfullHeightButton }) => {
    const [activeButton, setActiveButton] = useState(null);

    console.log(selectedButton, activeButton, selectedFilterfullHeightButton)

    const handleButtonClick = (buttonName) => {
        handleActiveButtonClick(buttonName);
        setActiveButton(buttonName);
    };

    useEffect(() => {
        if (selectedButton) {
            setActiveButton(selectedButton);
        }
    }, [selectedButton]);

    const [activeFilterButton, setActiveFilterButton] = useState(false);

    const handleFilterButtonClick = (buttonName) => {
        handleActiveFilterfullHeightButtonClick(!selectedFilterfullHeightButton);
        setActiveFilterButton(!selectedFilterfullHeightButton);
    };

    useEffect(() => {
        if (selectedFilterfullHeightButton) {
            setActiveFilterButton(selectedFilterfullHeightButton);
        }
    }, [selectedFilterfullHeightButton]);

    const buttonClass = (buttonName) =>
        `p-2 ${activeButton === buttonName ? 'border border-gray-400 bg-gray-200 rounded text-sm' : 'text-sm'}`;

    const filterbuttonClass = (buttonName) =>
        `p-2 ${activeFilterButton === true ? 'border border-gray-400 bg-gray-200 rounded text-sm' : 'text-sm'}`;

    // Conditionally check if Open Orders, Strategy Data, or MyOrders button is clicked
    const shouldShowFilterButtons = activeButton === 'openOrders' || activeButton === 'strategyData' || activeButton === 'MyOrders';

    return (
        <div className='w-full bg-white h-12 rounded'>
            <div className='w-full h-[100%] flex justify-between p-2'>
                <div className='flex items-center text-sm'>
                    <button
                        className={buttonClass('create_order')}
                        onClick={() => handleButtonClick('create_order')}
                    >
                        Create Order
                    </button>
                    <div className="flex items-center px-1 bg-white h-12">
                        <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                    </div>
                    <button
                        className={buttonClass('openOrders')}
                        onClick={() => handleButtonClick('openOrders')}
                    >
                        Open Orders
                    </button>
                    <div className="flex items-center px-1 bg-white h-12">
                        <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                    </div>
                    <button
                        className={buttonClass('strategyData')}
                        onClick={() => handleButtonClick('strategyData')}
                    >
                        Strategy Data
                    </button>
                    <div className="flex items-center px-1 bg-white h-12">
                        <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                    </div>
                    <button
                        className={buttonClass('aiAssistant')}
                        onClick={() => handleButtonClick('aiAssistant')}
                    >
                        AI Assistant
                    </button>
                </div>
                <div className='flex items-center text-sm'>
                    <button
                        className={buttonClass('MyOrders')}
                        onClick={() => handleButtonClick('MyOrders')}
                    >
                        My Orders
                    </button>

                    {/* Conditionally render "All Symbols" and "Previous Month" */}
                    {shouldShowFilterButtons && (
                        <>
                            <div className="flex items-center px-1 bg-white h-12">
                                <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                            </div>
                            <select className='p-2 outline-none cursor-pointer text-sm' >
                                <option>All Symbols</option>
                                <option>ETHUSDT</option>
                            </select>
                            <div className="flex items-center px-1 bg-white h-12">
                                <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                            </div>
                            <select className='p-2 outline-none cursor-pointer text-sm' >
                                <option>Previous Month</option>
                                <option>Previos Week</option>
                            </select>
                        </>
                    )}

                    <button
                        className={filterbuttonClass('fullHeight')}
                        onClick={() => handleFilterButtonClick('fullHeight')}
                    >
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0355f561f2b8bb78b777bd7fb78332444b799a54151b66afed1d475c11c28d55?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                            className="object-contain aspect-square w-6"
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BottomMenu;
