import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Dashboard.css';
import apiClient from '../actions/axiosInterceptor';
import baseURL from '../url';
import bulbImg from '../assets/image/bulb.png';
import PayPalCheckoutButton from './PayPalCheckoutButton';

const PaymentPage = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    // State to manage the selected button
    const [selectedButton, setSelectedButton] = useState('Billings');
    const [amount, setAmount] = useState(49);
    const [loading, setLoading] = useState(false);
    const [loadingPlan, setLoadingPlan] = useState(false);

    const [showPayPal, setShowPayPal] = useState(false);


    const [status, setStaus] = useState('Active');

    const [error, setError] = useState('');

    const [transections, setTransectionsData] = useState([]);
    const [activePlan, setActivePlan] = useState([]);

    const [loadingApi, setApiLoading] = useState(false);



    const fetchTransections = async () => {
        try {
            const response = await apiClient.get(`/auth/api/transections/`);
            const sortedTransections = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setTransectionsData(sortedTransections);
            setApiLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setApiLoading(false);
        }
    };



    const fetchActivePlan = async () => {
        try {
            const response = await apiClient.get(`/auth/api/active/plan/`);
            setActivePlan(response.data);
            console.log(response.data)
            fetchTransections();
            setApiLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setApiLoading(false);
        }
    };


    const saveActivePlan = async () => {
        setLoadingPlan(true);
        try {
            const response = await apiClient.post(`/auth/api/active/plan/`, {});
            setLoadingPlan(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setLoadingPlan(false);
        }
    };




    // Function to handle button clicks (Forex, Crypto, Stock)
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        if (buttonName === 'Basic') {
            setAmount(49);
        }
        else if (buttonName === 'Intermediate') {
            setAmount(69);
        }
        else if (buttonName === 'Advance') {
            setAmount(99);
        }
        else {
            setAmount(0)
        }
    };


    useEffect(() => {
        if (selectedButton === 'Billings') {
            setApiLoading(true);
            fetchTransections();
        }
        fetchActivePlan();
        setFormData({ ...formData, ['plan']: selectedButton });
    }, [selectedButton]);




    const handleShowPayPal = () => {
        setShowPayPal(true);
    };


    const handleClosePopup = () => {
        setShowPayPal(false); // Close the PayPal popup
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString(); // Format to show only the date
        const formattedTime = date.toLocaleTimeString(); // Format to show only the time
        return `${formattedDate} ${formattedTime}`;
    };

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [result, setResult] = useState('');
    const [showPopup, setShowPopup] = useState(false); // Control popup visibility
    const [formData, setFormData] = useState({
        coupon: '',
        plan: selectedButton
    });

    const handleUserNameClick = () => {
        setShowPopup(true); // Show the popup when name icon is clicked
        setResult('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable inputs and button


        try {
            console.log('Form data:', formData);

            // Send API request
            const response = await apiClient.post(`/auth/api/payment/create/`, formData); // No need to wrap formData in another object
            console.log(response.data)
            if (response.status === 200) {

                // Close the popup after successful submission
                // setShowPopup(false);
                // Optionally show a success message
                setResult('Form submitted successfully');
                window.location.reload();
            } else {

                // Handle non-200 responses (e.g., 400, 500)
                setResult(response.data.error);
            }

        } catch (error) {
            // Handle errors (e.g., network issues, server errors)
            console.error('Error submitting form:', error);
            setResult('An error occurred. Please try again.');
        } finally {
            // Re-enable the inputs and button after submission (successful or not)
            setIsSubmitting(false);
        }
    };

    const closePopup = () => {
        setShowPopup(false); // Close the popup when clicking outside or cancel
    };

    // Function to dynamically load the Calendly script
    const loadCalendlyPopup = () => {
        // Check if the script is already loaded
        if (!document.getElementById('calendly-script')) {
            const script = document.createElement('script');
            script.src = 'https://assets.calendly.com/assets/external/widget.js';
            script.id = 'calendly-script'; // Add an ID to avoid reloading it multiple times
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (window.Calendly) {
                    window.Calendly.initPopupWidget({
                        url: 'https://calendly.com/hedgeplus/meet-with-team-hedge',
                    });
                } else {
                    console.error('Calendly failed to load');
                }
            };
        } else {
            // If the script is already loaded, directly open the popup
            if (window.Calendly) {
                window.Calendly.initPopupWidget({
                    url: 'https://calendly.com/hedgeplus/meet-with-team-hedge',
                });
            } else {
                console.error('Calendly is not available');
            }
        }
    };

    // Function to add Calendly CSS dynamically
    const addCalendlyCss = () => {
        if (!document.getElementById('calendly-css')) {
            const link = document.createElement('link');
            link.href = 'https://assets.calendly.com/assets/external/widget.css';
            link.rel = 'stylesheet';
            link.id = 'calendly-css';
            document.head.appendChild(link);
        }
    };

    // Add the Calendly CSS when the component is mounted
    useEffect(() => {
        addCalendlyCss();
    }, []);


    return (
        <div className={`h-[100%] ${selectedFilterfullHeightButton === true ? '' : 'pb-[10%]'} transform transition-all duration-500 ease-out translate-y-full opacity-0 animate-slide-down`} >
            <div className='h-[100%] bg-white rounded rounded-bl-[14px] rounded-br-[14px] p-[10px]'>
                <div className='h-[calc(95%)]'>
                    <div className='h-[calc(100%)]'>
                        <div className='flex border-b border-gray-500 align-center'>
                            <h1 style={{ fontSize: "25px", fontWeight: "bold", padding: "10px", width: "25%" }} className='pl-2'>Our Plans</h1>
                            {selectedButton !== 'Billings' && (
                                <p style={{ fontSize: "12px", display: "flex", alignItems: "center", backgroundColor: "#79609F", color: "white", width: "78%", margin: "10px", paddingLeft: "10px", justifyContent: "flex-start" }}>
                                    {activePlan.plan?.plan_name ? `Currently ${activePlan.plan.plan_name} Plan is activated.` : "Start your 30-day free trial - no credit card needed!"}
                                    &nbsp; {loadingPlan ? (
                                        <svg
                                            className="w-5 h-5 mr-3 text-white animate-spin"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                    ) : null}
                                    {!activePlan.plan?.plan_name &&
                                        <p onClick={saveActivePlan} style={{ textDecoration: "underline", cursor: "pointer" }} > {loadingPlan ? "Activating..." : "Activate Now"}</p>
                                    }
                                </p>
                            )}
                        </div>
                        <div className='flex align-center h-[calc(100%-2rem)]'>
                            <div style={{ width: "25%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }} className='border-r border-gray-500' >
                                <div style={{ padding: "10px", width: "100%", justifyContent: "flex-start" }} className='flex flex-col align-start'>
                                    <button
                                        className={`border border-gray-500 p-3 m-2 ${selectedButton === 'Basic' ? 'bg-black text-white' : ''}`}
                                        onClick={() => handleButtonClick('Basic')}
                                    >
                                        Basic
                                    </button>
                                    <button
                                        className={`border border-gray-500 p-3 m-2 ${selectedButton === 'Intermediate' ? 'bg-black text-white' : ''}`}
                                        onClick={() => handleButtonClick('Intermediate')}
                                    >
                                        Intermediate
                                    </button>
                                    <button
                                        className={`border border-gray-500 p-3 m-2 ${selectedButton === 'Advance' ? 'bg-black text-white' : ''}`}
                                        onClick={() => handleButtonClick('Advance')}
                                    >
                                        Advance
                                    </button>
                                    <button
                                        className={`border border-gray-500 p-3 m-2 ${selectedButton === 'Custom Plan' ? 'bg-black text-white' : ''}`}
                                        onClick={() => handleButtonClick('Custom Plan')}
                                    >
                                        Custom Plan
                                    </button>
                                </div>
                                <div style={{ padding: "10px", width: "100%", justifyContent: "center", alignItems: "center" }} className='flex flex-col align-start'>
                                    <button
                                        className={`border w-[92%] border-gray-500 p-3 m-2 ${selectedButton === 'Billings' ? 'bg-black text-white' : ''}`}
                                        onClick={() => handleButtonClick('Billings')}
                                    >
                                        Billings
                                    </button>
                                </div>
                            </div>

                            <div style={{ fontSize: "12px", width: "75%", margin: "10px" }}>
                                {error && <p className="text-red-500">{error}</p>}
                                {selectedButton === 'Basic' && (
                                    <form className='h-full flex flex-col justify-between items-end'>
                                        <div className='w-full'>
                                            <ul class="list-none space-y-2 p-3">
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Strategy Data</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Orders</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>2 Api Keys</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Ai Assistent (Strategy Builder)</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Devices</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Supports Long</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Support Short</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>All Markets</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>All Brokers</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-grey-900 h-4 w-4 flex items-center justify-center rounded-full" style={{ backgroundColor: "grey" }} >
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Custom Strategy (Advance)</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-grey-900 h-4 w-4 flex items-center justify-center rounded-full" style={{ backgroundColor: "grey" }} >
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Dedicated Support Manager (Advance)</span>
                                                </li>
                                            </ul>

                                        </div>
                                        <div className='flex'>
                                            {['Before subscribing',].map((strategy, index) => (
                                                <div key={index} className='w-[80%] p-[20px] border m-2 bg-[#F5F5F5]'>
                                                    <span className='' >
                                                        <img src={bulbImg} className='w-8' alt="Info" />
                                                    </span>
                                                    <p className='m-2' >
                                                        {strategy === 'Before subscribing' && "Before subscribing, backtest our strategy on TradingView. Once satisfied, proceed with your order. You're responsible for all your trades and decisions. We offer insights, but can't guarantee outcomes. Trading involves risk—stay sharp and trade smart!"}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        {!activePlan.plan?.plan_name &&
                                            <>
                                                <div className='flex' >

                                                    <a onClick={handleUserNameClick} type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2'>

                                                        <div className="flex items-center justify-center">
                                                            {loading ? (
                                                                <svg
                                                                    className="w-5 h-5 mr-3 text-white animate-spin"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <circle
                                                                        className="opacity-25"
                                                                        cx="12"
                                                                        cy="12"
                                                                        r="10"
                                                                        stroke="currentColor"
                                                                        strokeWidth="4"
                                                                    ></circle>
                                                                    <path
                                                                        className="opacity-75"
                                                                        fill="currentColor"
                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                                    ></path>
                                                                </svg>
                                                            ) : null}
                                                            {loading ? "Submiting..." : "Pay With Coupen"}
                                                        </div>

                                                    </a>
                                                    <a onClick={handleShowPayPal} type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2'>

                                                        <div className="flex items-center justify-center">
                                                            {loading ? (
                                                                <svg
                                                                    className="w-5 h-5 mr-3 text-white animate-spin"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                >
                                                                    <circle
                                                                        className="opacity-25"
                                                                        cx="12"
                                                                        cy="12"
                                                                        r="10"
                                                                        stroke="currentColor"
                                                                        strokeWidth="4"
                                                                    ></circle>
                                                                    <path
                                                                        className="opacity-75"
                                                                        fill="currentColor"
                                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                                    ></path>
                                                                </svg>
                                                            ) : null}
                                                            {loading ? "Submiting..." : "Pay $49"}
                                                        </div>

                                                    </a>
                                                </div>

                                            </>
                                        }
                                    </form>
                                )}

                                {selectedButton === 'Intermediate' && (
                                    <form className='h-full flex flex-col justify-between items-end' >
                                        <div className='w-full'>
                                            <ul class="list-none space-y-2 p-3">
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Strategy Data</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Orders</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>4 Api Keys</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Ai Assistent (Strategy Builder)</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Devices</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Supports Long</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Support Short</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>All Markets</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>All Brokers</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-grey-900 h-4 w-4 flex items-center justify-center rounded-full" style={{ backgroundColor: "grey" }} >
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Custom Strategy (Advance)</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-grey-900 h-4 w-4 flex items-center justify-center rounded-full" style={{ backgroundColor: "grey" }} >
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Dedicated Support Manager (Advance)</span>
                                                </li>
                                            </ul>

                                        </div>
                                        <div className='flex'>
                                            {['Before subscribing',].map((strategy, index) => (
                                                <div key={index} className='w-[80%] p-[20px] border m-2 bg-[#F5F5F5]'>
                                                    <span className='' >
                                                        <img src={bulbImg} className='w-8' alt="Info" />
                                                    </span>
                                                    <p className='m-2' >
                                                        {strategy === 'Before subscribing' && "Before subscribing, backtest our strategy on TradingView. Once satisfied, proceed with your order. You're responsible for all your trades and decisions. We offer insights, but can't guarantee outcomes. Trading involves risk—stay sharp and trade smart!"}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        {!activePlan.plan?.plan_name &&
                                            <div className='flex' >

                                                <a onClick={handleUserNameClick} type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2'>

                                                    <div className="flex items-center justify-center">
                                                        {loading ? (
                                                            <svg
                                                                className="w-5 h-5 mr-3 text-white animate-spin"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                                ></path>
                                                            </svg>
                                                        ) : null}
                                                        {loading ? "Submiting..." : "Pay With Coupen"}
                                                    </div>

                                                </a>
                                                <a onClick={handleShowPayPal} type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2'>
                                                    <div className="flex items-center justify-center">
                                                        {loading ? (
                                                            <svg
                                                                className="w-5 h-5 mr-3 text-white animate-spin"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                                ></path>
                                                            </svg>
                                                        ) : null}
                                                        {loading ? "Submiting..." : "Pay $69"}
                                                    </div>

                                                </a>
                                            </div>
                                        }
                                    </form>
                                )}

                                {selectedButton === 'Advance' && (
                                    <form className='h-full flex flex-col justify-between items-end'>
                                        <div className='w-full'>
                                            <ul class="list-none space-y-2 p-3">
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Strategy Data</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Orders</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>8 Api Keys</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Ai Assistent (Strategy Builder)</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Unlimited Devices</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Supports Long</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Support Short</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>All Markets</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>All Brokers</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Custom Strategy (Advance)</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Dedicated Support Manager (Advance)</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='flex'>
                                            {['Before subscribing',].map((strategy, index) => (
                                                <div key={index} className='w-[80%] p-[20px] border m-2 bg-[#F5F5F5]'>
                                                    <span className='' >
                                                        <img src={bulbImg} className='w-8' alt="Info" />
                                                    </span>
                                                    <p className='m-2' >
                                                        {strategy === 'Before subscribing' && "Before subscribing, backtest our strategy on TradingView. Once satisfied, proceed with your order. You're responsible for all your trades and decisions. We offer insights, but can't guarantee outcomes. Trading involves risk—stay sharp and trade smart!"}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        {!activePlan.plan?.plan_name &&
                                            <div className='flex' >

                                                <a onClick={handleUserNameClick} type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2'>

                                                    <div className="flex items-center justify-center">
                                                        {loading ? (
                                                            <svg
                                                                className="w-5 h-5 mr-3 text-white animate-spin"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                                ></path>
                                                            </svg>
                                                        ) : null}
                                                        {loading ? "Submiting..." : "Pay With Coupen"}
                                                    </div>

                                                </a>
                                                <a onClick={handleShowPayPal} type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2'>

                                                    <div className="flex items-center justify-center">
                                                        {loading ? (
                                                            <svg
                                                                className="w-5 h-5 mr-3 text-white animate-spin"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                                ></path>
                                                            </svg>
                                                        ) : null}
                                                        {loading ? "Submiting..." : "Pay $99"}
                                                    </div>

                                                </a>
                                            </div>
                                        }
                                    </form>
                                )}

                                {selectedButton === 'Custom Plan' && (
                                    <form className='h-full flex flex-col justify-between items-end' >
                                        <div className='w-full'>
                                            <ul class="list-none space-y-2 p-3">
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Build Your Custom Strategy</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Request New Broker Implementations</span>
                                                </li>
                                                <li class="flex items-center">
                                                    <span class="text-white bg-black h-4 w-4 flex items-center justify-center rounded-full">
                                                        &#10003;
                                                    </span>
                                                    <span className='ml-2'>Deep Back Testing</span>
                                                </li>


                                            </ul>

                                        </div>
                                        <div className='flex'>
                                            {['Before subscribing',].map((strategy, index) => (
                                                <div key={index} className='w-[80%] p-[20px] border m-2 bg-[#F5F5F5]'>
                                                    <span className='' >
                                                        <img src={bulbImg} className='w-8' alt="Info" />
                                                    </span>
                                                    <p className='m-2' >
                                                        {strategy === 'Before subscribing' && "Before subscribing, backtest our strategy on TradingView. Once satisfied, proceed with your order. You're responsible for all your trades and decisions. We offer insights, but can't guarantee outcomes. Trading involves risk—stay sharp and trade smart!"}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                        <a type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2' onClick={loadCalendlyPopup}>

                                            <div className="flex items-center justify-center">
                                                {loading ? (
                                                    <svg
                                                        className="w-5 h-5 mr-3 text-white animate-spin"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                        ></path>
                                                    </svg>
                                                ) : null}
                                                {loading ? "Submiting..." : "Get on a Call"}
                                            </div>

                                        </a>
                                    </form>
                                )}

                                {selectedButton === 'Billings' && (
                                    <div className='h-full'>
                                        <div className='overflow-x-auto h-[calc(100%)]'>
                                            {loadingApi ? ( // Show loader if loading is true
                                                <div className="flex justify-center items-center h-full">
                                                    <div className="loader"></div>
                                                </div>
                                            ) : ( // Display table when data is loaded
                                                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                                                    <thead>
                                                        <tr className="bg-gray-200">
                                                            <th className="border border-gray-300  text-left text-sm p-2">Plan</th>
                                                            <th className="border border-gray-300  text-left text-sm p-2">Paid Amount</th>
                                                            <th className="border border-gray-300  text-left text-sm p-2">Date</th>
                                                            <th className="border border-gray-300  text-left text-sm p-2">End Date</th>
                                                            <th className="border border-gray-300  text-left text-sm p-2">Help</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {transections.map((t, index) => (
                                                            <tr key={index} className="hover:bg-gray-100">
                                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{t.plan.plan_name}</td>
                                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{t.coupon ? 'Pay Using Coupon' : `$${t.price}`}</td>
                                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{formatDate(t.start_date)}</td>
                                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{formatDate(t.end_date)}</td>
                                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm flex items-center">
                                                                    {t.status ? (t.status) : (<p>&nbsp;</p>)}

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}

                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>
                        {showPayPal && (
                            <div className="paypal-popup">
                                <button
                                    onClick={handleClosePopup}
                                    className="close-button"
                                >
                                    &times; {/* '×' is a multiplication symbol to represent 'close' */}
                                </button>
                                <div className="paypal-buttons-container w-full">
                                    <PayPalCheckoutButton amount={amount} selectedButton={selectedButton} setShowPayPal={setShowPayPal} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {showPopup && (
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                    // onClick={closePopup}
                    >
                        <div
                            className="bg-white rounded-lg p-6 w-[450px]"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <h2 className="text-lg font-bold mb-4">Pay With Coupen</h2>
                            {result && <p className="text-red-500" >{result}</p>}
                            <form onSubmit={handleFormSubmit}>

                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Coupon Code</label>
                                    <input
                                        type="text"
                                        name="coupon"
                                        value={formData.coupon}
                                        onChange={handleInputChange}
                                        placeholder=''
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                        disabled={isSubmitting} // Disable input when submitting
                                    />
                                </div>

                                <div className="flex justify-end">
                                    <button
                                        type="button"
                                        className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
                                        onClick={closePopup}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        type="submit"
                                        className="w-full bg-blue-500 text-white px-4 py-2 rounded-md flex items-center justify-center"
                                        disabled={isSubmitting} // Disable button when submitting
                                    >
                                        <div className="flex items-center justify-center">
                                            {isSubmitting ? (
                                                <svg
                                                    className="w-5 h-5 mr-3 text-white animate-spin"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    ></circle>
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                    ></path>
                                                </svg>
                                            ) : null}
                                            {isSubmitting ? "Submiting..." : "Pay"}
                                        </div>

                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
};

export default PaymentPage;
