import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import './index.css';
import App from './App';
import SignIn from './Page/auth/SignIn'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './actions/store';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';


// src/index.js
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="243756703301-0509b8th1on1chsehkh8fd5mcps22us8.apps.googleusercontent.com">

        <Router>
          <App />
        </Router>
      </GoogleOAuthProvider>

    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
