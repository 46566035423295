import React from 'react';
import '../assets/css/Dashboard.css'; // Assuming custom styles are already here

const AiAssistent = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    return (
        <div className={`h-full w-full p-6 bg-white rounded-xl shadow-lg ${selectedFilterfullHeightButton ? 'visible' : ''} transform transition-all duration-500 ease-out`}>
            <div className="h-full w-full rounded-lg p-8">
                <div className="max-w-5xl mx-auto p-8 h-full w-full flex items-center justify-center">

                    <p className="text-gray-700 text-lg w-[50%] leading-relaxed text-justify">
                        Hedge+ Assistant, powered by GPT, is coming soon to make placing orders and managing risk a breeze.
                        Get instant, accurate answers to your questions anytime.
                        Benefit from clear, step-by-step guidance for smarter trading.
                        Stay tuned for a seamless trading experience!
                    </p>

                </div>
            </div>
        </div>
    );
};

export default AiAssistent;
