// Push.js
import React, { useState } from "react";
import PushNotification from "./../../PushNotification";

function Push() {
    const [message, setMessage] = useState("Welcome to Push Notifications!");

    function notifyMe() {
        if (!("Notification" in window)) {
            // Check if the browser supports notifications
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            // Check whether notification permissions have already been granted;
            // if so, create a notification
            const notification = new Notification("Hi there!");
            // …
        } else if (Notification.permission !== "denied") {
            // We need to ask the user for permission
            Notification.requestPermission().then((permission) => {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    const notification = new Notification("Hi there!");
                    // …
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them anymore.
    }

    return (
        <div className="App">
            <h1>React Push Notifications</h1>
            <input
                type="text"
                placeholder="Enter notification message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <PushNotification message={message} />

            <button onClick={notifyMe()}>Notify me!</button>

        </div>
    );
}

export default Push;
