import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Dashboard.css';
import axios from 'axios';
import baseURL from './../url';
import apiClient from './../actions/axiosInterceptor';
import DateTimeWithTooltip from './DateTimeWithTooltip';

const StrategyData = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    const [page, setPage] = useState(1);
    const [trades, setTrades] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sloading, setSLoading] = useState(true);

    const [maxPage, setMaxPage] = useState(1);

    const [strategy, setStrategy] = useState('');
    const [markets, setMarket] = useState('');
    const [symbols, setSymbols] = useState('');
    const [local_currency, setLocalCurrency] = useState('');
    const [Long, setLong] = useState('');
    const [Short, setShort] = useState('');



    useEffect(() => {
        const fetchTrades = async () => {
            setSLoading(true);
            try {
                const response = await apiClient.get(`${baseURL}/analytics/trade/alert/?page=${page}`);
                console.log(page)
                console.log(response)
                const totalData = response.data.count;
                const totalResultData = Object.keys(response.data.results.data).length;
                console.log(totalData / totalResultData);
                const maxPageNo = Math.floor(totalData / totalResultData) + 1;
                setMaxPage(maxPageNo);
                const sortedTrades = response.data.results.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setTrades((prevTrades) => [...prevTrades, ...sortedTrades]);
                setStrategy(response.data.results.unique_strategy_count);
                setMarket(response.data.results.unique_markets_count);
                setSymbols(response.data.results.unique_symbols_count);
                setLocalCurrency(response.data.results.local_currency);
                setLong(response.data.results.total_long_count);
                setShort(response.data.results.total_short_count);
            } catch (err) {
                console.error('Error fetching trades:', err);
                setError('Failed to fetch trades');
            } finally {
                setSLoading(false);
                setLoading(false);
            }
        };

        fetchTrades();

        const socket = new WebSocket('wss://beta.hedgemarc.ca/ws/trades/');

        socket.onopen = () => {
            console.log('WebSocket connection established');
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                // if (data.trade_id && data.trade_data) {
                //     setTrades((prevTrades) => [data.trade_data, ...prevTrades]);
                // }
                fetchTrades();
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        socket.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        socket.onclose = (event) => {
            console.log('WebSocket connection closed:', event.reason);
        };

        return () => {
            socket.close();
        };
    }, [page, accessToken]);


    const scrollRef = useRef(null);

    // Scroll event listener to load more data when reaching bottom
    const handleScroll = () => {
        const scrollDiv = scrollRef.current;
        if (scrollDiv.scrollTop + scrollDiv.clientHeight >= scrollDiv.scrollHeight && !loading) {
            // Reached the bottom of the scrollable div, load more data
            setPage((prevPage) => {
                if (prevPage < maxPage) {
                    return prevPage + 1;
                }
                return prevPage;
            }
            );
        }
    };


    // Calculate unique symbols, brokers, total value, and long/short counts
    const calculateMetrics = (trades) => {
        const uniqueSymbols = new Set();
        const uniqueStrategies = new Set();
        const uniqueBrokers = new Set();
        const uniqueMarkets = new Set();
        let totalValue = 0;
        let longCount = 0;
        let shortCount = 0;

        trades.forEach(trade => {
            uniqueSymbols.add(trade.symbol);
            uniqueBrokers.add(trade.broker);
            uniqueMarkets.add(trade.market);
            uniqueStrategies.add(trade.strategy);
            totalValue += parseFloat(trade.pl || 0); // Assuming P&L is the value to sum

            if (trade.entry_type === 'Long') {
                longCount++;
            } else if (trade.entry_type === 'Short') {
                shortCount++;
            }
        });

        return {
            uniqueSymbolCount: uniqueSymbols.size,
            uniqueBrokerCount: uniqueBrokers.size,
            uniqueMarketCount: uniqueMarkets.size,
            uniqueStrategiesCount: uniqueStrategies.size,
            totalValue: totalValue.toFixed(2), // Assuming total value needs to be in two decimal places
            longCount,
            shortCount,
            currency: trades.length > 0 ? trades[0].currency : 'N/A' // Assuming currency is the same for all trades
        };
    };

    const metrics = calculateMetrics(trades);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = date.toLocaleDateString(); // Format to show only the date
        const formattedTime = date.toLocaleTimeString(); // Format to show only the time
        return `${formattedDate} ${formattedTime}`;
    };

    return (
        <div className={`h-[100%] ${selectedFilterfullHeightButton === true ? '' : 'pt-[10%]'} transform transition-all duration-500 ease-out translate-y-full opacity-0 animate-slide-up`} >
            <div className='h-[100%] bg-white rounded rounded-tl-[14px] rounded-tr-[14px] p-[10px]' >
                <div className='flex' >

                    <div className='flex bg-[#E5E5E5] p-3 m-2 rounded-[10px] w-[20%]'>
                        <h3 className='w-[50%] text-xs' >Strategy</h3>
                        <h3 className='text-xs' style={{ color: "green" }}>#{strategy}</h3>
                    </div>
                    <div className='flex bg-[#E5E5E5] p-3 m-2 rounded-[10px] w-[20%]'>
                        <h3 className='w-[50%] text-xs' >Market</h3>
                        <h3 className='text-xs' style={{ color: "green" }}>#{markets}</h3>
                    </div>
                    <div className='flex bg-[#E5E5E5] p-3 m-2 rounded-[10px] w-[20%]'>
                        <h3 className='w-[50%] text-xs' >Symbols</h3>
                        <h3 className='text-xs' style={{ color: "green" }}>#{symbols}</h3>
                    </div>
                    {/* <div className='flex bg-[#E5E5E5] p-3 m-2 rounded-[10px] w-[20%]'>
                        <h3 className='w-[50%] text-xs' >Total Value</h3>
                        <h3 className='text-xs' style={{ color: "green" }}>${metrics.totalValue}</h3>
                    </div> */}

                    <div className='flex bg-[#E5E5E5] p-3 m-2 rounded-[10px] w-[20%]'>
                        <h3 className='w-[70%] text-xs' >Local Currency</h3>
                        <h3 className='text-xs' style={{ color: "green" }}>{local_currency}</h3>
                    </div>

                    <div className='flex bg-[#E5E5E5]  p-3 m-2 rounded-[10px] w-[20%]'>
                        <h3 className='w-[70%] text-xs' >Long/Short</h3>
                        <h3 className='text-xs' style={{ color: "green" }}>#{Long}/{Short}</h3>
                    </div>

                </div>
                <div className="overflow-x-auto h-[calc(100%-4rem)]" ref={scrollRef}
                    onScroll={handleScroll}>
                    {loading ? ( // Show loader if loading is true
                        <div className="flex justify-center items-center h-full">
                            <div className="loader"></div>
                        </div>
                    ) : ( // Display table when data is loaded
                        <>

                            <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border border-gray-300  text-left text-xs p-1">Strategy</th>
                                        <th className="border border-gray-300  text-left text-xs p-1">Market</th>
                                        {/* <th className="border border-gray-300  text-left text-xs p-1">Broker</th> */}
                                        <th className="border border-gray-300  text-left text-xs p-1">Symbol</th>
                                        <th className="border border-gray-300  text-left text-xs p-1">Entry Price</th>
                                        {/* <th className="border border-gray-300  text-left text-xs p-1">Exit Price</th> */}
                                        {/* <th className="border border-gray-300  text-left text-xs p-1">P&L</th> */}
                                        <th className="border border-gray-300  text-left text-xs p-1">Interval</th>
                                        <th className="border border-gray-300  text-left text-xs p-1">Entry Type</th>
                                        {/* <th className="border border-gray-300  text-left text-xs p-1">Stop Loss</th>
                                    <th className="border border-gray-300  text-left text-xs p-1">Order No.</th> */}
                                        <th className="border border-gray-300  text-left text-xs p-1">Date</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {trades.map((trade, index) => {
                                        // Move the variable declaration here, outside of the JSX
                                        const { formattedDate, formattedTime } = formatDate(trade.created_at);

                                        return (
                                            <tr key={index} className="hover:bg-gray-100">
                                                <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.strategy}</td>
                                                <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.market}</td>
                                                {/* <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.broker}</td> */}
                                                <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.symbol}</td>
                                                <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.entry_price}</td>
                                                {/* <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.exit_price}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.pl}</td> */}
                                                <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.time_frame}</td>
                                                <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.entry_type}</td>
                                                {/* <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.stop_loss}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">{trade.order_no}</td> */}
                                                <td className="border border-gray-300 p-1 whitespace-nowrap text-xs ">
                                                    <DateTimeWithTooltip dateString={trade.created_at} /> {/* Date with tooltip */}
                                                </td>

                                            </tr>
                                        );
                                    })}
                                </tbody>

                            </table>
                            {sloading && ( // Show loader if loading is true
                                <div className="flex justify-center items-center">
                                    <div className="loader"></div>
                                </div>
                            )}
                        </>

                    )}
                </div>
            </div>
        </div>
    );
};

export default StrategyData;
