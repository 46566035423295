import React, { useState } from 'react';
import '../assets/css/Dashboard.css';
import { FaStoreAlt, FaMoneyBillWave, FaBitcoin, FaChartLine } from 'react-icons/fa';

const LeftComponent = ({ handleActiveButtonClick }) => {
    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        handleActiveButtonClick(buttonName);
        setActiveButton(buttonName);
    };

    // Function to determine the button styles
    const getButtonStyle = (buttonName) => {
        return activeButton === buttonName ? 'bg-gray-300 p-2 rounded' : ''; // Grey background for active button
    };

    return (
        <div className='w-full h-full bg-white flex flex-col items-center'>
            <div className={`relative mt-2 mb-2 cursor-pointer group ${getButtonStyle('markets_charts')}`} onClick={() => handleButtonClick('markets_charts')}>
                <FaStoreAlt size={18} color="grey" />
                <span className="absolute left-8 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity z-[999]">
                    Markets
                </span>
            </div>
            <div className={`relative mb-2 mt-2 cursor-pointer group ${getButtonStyle('forex_charts')}`} onClick={() => handleButtonClick('forex_charts')}>
                <FaMoneyBillWave size={18} color="grey" />
                <span className="absolute left-8 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity z-[999]">
                    Forex
                </span>
            </div>
            <div className={`relative mb-2 mt-2 cursor-pointer group ${getButtonStyle('crypto_charts')}`} onClick={() => handleButtonClick('crypto_charts')}>
                <FaBitcoin size={18} color="grey" />
                <span className="absolute left-8 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity z-[999]">
                    Crypto
                </span>
            </div>
            <div className={`relative mb-2 mt-2 cursor-pointer group ${getButtonStyle('stocks_charts')}`} onClick={() => handleButtonClick('stocks_charts')}>
                <FaChartLine size={18} color="grey" />
                <span className="absolute left-8 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white text-xs rounded px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity z-[999]">
                    Stocks
                </span>
            </div>
        </div>
    );
};

export default LeftComponent;
