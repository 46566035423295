import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Dashboard.css';
import baseURL from '../url';
import apiClient from '../actions/axiosInterceptor';
import DateTimeWithTooltip from './DateTimeWithTooltip';


const MyOrders = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    const [activeTrades, setActiveTrades] = useState([]); // State to store trades
    const [trades, setTrades] = useState([]); // State to store trades
    const [error, setError] = useState(null);  // State to handle error
    const [loading, setLoading] = useState(true); // State for loader
    const [showConfirm, setShowConfirm] = useState({ open: false, action: '', tradeId: null }); // State for confirmation dialog
    const [dropdownVisible, setDropdownVisible] = useState(null); // State to track which dropdown is visible
    const [actionLoading, setActionLoading] = useState(false); // State to handle loading for actions
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const dropdownRef = useRef(null); // Ref to track the dropdown

    // Fetch initial trades data from the API
    const fetchActiveTrades = async () => {
        try {
            const response = await apiClient.get(`${baseURL}/analytics/api/create-order/?status=Active`);
            const sortedTrades = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            console.log(sortedTrades)
            setActiveTrades(sortedTrades);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setError('Failed to fetch trades');
            setLoading(false);
        }
    };
    // Fetch initial trades data from the API
    const fetchTrades = async () => {
        try {
            const response = await apiClient.get(`${baseURL}/analytics/api/create-order/?status=Closed`);
            const sortedTrades = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            console.log(sortedTrades)
            setTrades(sortedTrades);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setError('Failed to fetch trades');
            setLoading(false);
        }
    };


    useEffect(() => {

        fetchActiveTrades();
    }, [accessToken]);


    useEffect(() => {

        fetchTrades();
    }, [accessToken]);


    useEffect(() => {
        // Function to close dropdown if clicked outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(null); // Close the dropdown
            }
        };

        // Attach event listener for click outside
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible]);

    // Handle trade deletion
    const handleDelete = async (tradeId) => {
        setActionLoading(true); // Set loading to true
        try {
            await apiClient.delete(`/analytics/api/create-order/?id=${tradeId}`);
            setTrades(trades.filter(trade => trade.id !== tradeId)); // Remove deleted trade from UI
            setShowConfirm({ open: false, action: '', tradeId: null }); // Close confirmation
        } catch (err) {
            console.error('Error deleting trade:', err);
            setError('Failed to delete trade');
        } finally {
            setActionLoading(false); // Set loading back to false
        }
    };

    // Handle trade closure
    const handleClose = async (tradeId) => {
        setActionLoading(true); // Set loading to true
        const tradeToUpdate = activeTrades.find(trade => trade.id === tradeId);
        const newStatus = tradeToUpdate.status === 'Active' ? 'Closed' : 'Active';
        try {
            await apiClient.patch(`${baseURL}/analytics/api/create-order/?id=${tradeId}`, { status: newStatus });
            setTrades(activeTrades.map(trade => trade.id === tradeId ? { ...trade, status: newStatus } : trade)); // Update trade status in UI
            setShowConfirm({ open: false, action: '', tradeId: null }); // Close confirmation

            fetchActiveTrades();
            fetchTrades();
        } catch (err) {
            console.error(`Error updating trade status to ${newStatus}:`, err);
            setError(`Failed to update trade status to ${newStatus}`);
        } finally {
            setActionLoading(false); // Set loading back to false
        }
    };

    // Toggle trade status between 'Active' and 'Closed'
    const toggleTradeStatus = async (tradeId) => {
        setActionLoading(true); // Set loading to true
        const tradeToUpdate = trades.find(trade => trade.id === tradeId);
        const newStatus = tradeToUpdate.status === 'Active' ? 'Closed' : 'Active';
        try {
            await apiClient.patch(`${baseURL}/analytics/api/create-order/?id=${tradeId}`, { status: newStatus });
            setTrades(trades.map(trade => trade.id === tradeId ? { ...trade, status: newStatus } : trade)); // Update trade status in UI
            fetchActiveTrades();
            fetchTrades();
        } catch (err) {
            console.error(`Error updating trade status to ${newStatus}:`, err);
            setError(`Failed to update trade status to ${newStatus}`);
        } finally {
            setActionLoading(false); // Set loading back to false
        }
    };

    // Show confirmation dialog
    const confirmAction = (action, tradeId) => {
        setShowConfirm({ open: true, action, tradeId });
    };

    // Toggle dropdown visibility for a specific trade
    const toggleDropdown = (tradeId) => {
        setDropdownVisible(dropdownVisible === tradeId ? null : tradeId); // Toggle the visibility of the dropdown for the clicked trade
    };


    // Set initial toggle state; adjust this based on your initial trade.swap value
    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = async (tradeId, status) => {
        // Toggle the checked state
        const newCheckedState = !status;

        // Call the API with the new state of trade.swap
        try {

            const response = await apiClient.patch(`${baseURL}/analytics/api/create-order/?id=${tradeId}`, { swap: newCheckedState });
            // setIsChecked(newCheckedState);
            fetchActiveTrades();
            // Handle response here if needed
        } catch (error) {
            console.error('Error:', error);
            // Handle error here if needed
        }
    };


    return (
        <div className={`h-[100%] ${selectedFilterfullHeightButton === true ? '' : 'pt-[10%]'} transform transition-all duration-500 ease-out translate-y-full opacity-0 animate-slide-up`} >
            <div className='w-full bg-white h-[30%] mb-1 rounded-[14px]' >

                <div className='h-[100%] w-full bg-white rounded rounded-tl-[14px] rounded-tr-[14px] p-[10px]' >
                    <div className="overflow-x-auto h-[calc(100%)]">
                        {loading ? ( // Show loader if loading is true
                            <div className="flex justify-center items-center h-full">
                                <div className="loader"></div>
                            </div>
                        ) : ( // Display table when data is loaded
                            <table className="min-w-full divide-y divide-gray-200 border border-gray-300"
                                style={{ maxHeight: '80vh', fontSize: '12px', overflowY: 'auto' }}>
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border border-gray-300 text-left text-xs p-1">Strategy</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Market</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Broker</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Symbol</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">API</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Order Type</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Spot/Limit</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Time Frame</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Long/Short</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Risk Value</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Date/Time</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Swap</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Status</th>
                                        {/* <th className="border border-gray-300 text-left text-xs p-1">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200" style={{ maxHeight: 'calc(80vh - 50px)', overflowY: 'auto' }}>
                                    {activeTrades.map((trade, index) => (
                                        <tr key={index} className="hover:bg-gray-100">
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.strategy}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.market}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.broker}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.symbol}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.api?.api_name}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.order_type}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.option_strategies}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.time_frame}</td>
                                            <td
                                                className="relative border border-gray-300 text-xs text-center p-1"
                                                onMouseEnter={() => setTooltipVisible(true)}  // Show tooltip on hover
                                                onMouseLeave={() => setTooltipVisible(false)}  // Hide tooltip when mouse leaves
                                            >
                                                {trade.long_or_short} {trade?.swap ? '(s)' : ''}

                                                {/* Tooltip */}
                                                {trade?.swap && tooltipVisible && (
                                                    <div className="absolute bottom-full mt-2 w-20 p-1 bg-black text-white text-xs rounded-lg shadow-lg">
                                                        {trade.long_or_short === 'Long' ? 'Short' : 'Long'} Close
                                                    </div>
                                                )}
                                            </td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.risk_value}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">
                                                <DateTimeWithTooltip dateString={trade.created_at} />
                                            </td>
                                            <td className='text-center border border-gray-300 p-1 whitespace-nowrap text-xs'>
                                                <div class="flex items-center" tooltip="All long positions will now be converted to short positions and traded. Please test this setup before finalizing.">
                                                    <label class="relative inline-flex items-center cursor-pointer">
                                                        {/* <input type="checkbox" id="toggleButton" class="sr-only peer" checked={trade.swap}
                                                            onChange={() => handleToggle(trade.id, trade.swap)}

                                                        /> */}
                                                        {/* <div className="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600 transition duration-300"></div>
                                                        <div className="absolute left-0.5 top-0.5 w-5 h-5 bg-white border border-gray-300 rounded-full transform transition-transform duration-300 peer-checked:translate-x-5 peer-checked:border-transparent"></div> */}
                                                        <input type="checkbox" id="" class="sr-only peer" checked={trade.swap} />
                                                        <div className="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:bg-gray-700 transition duration-300"></div>
                                                        <div className="absolute left-0.5 top-0.5 w-5 h-5 bg-white border border-gray-300 rounded-full transform transition-transform duration-300 peer-checked:translate-x-5 peer-checked:border-transparent"></div>
                                                    </label>
                                                </div>
                                            </td>


                                            <td className="p-1 whitespace-nowrap text-xs flex items-center">
                                                {trade.status}
                                                {trade.status === 'Active' && (
                                                    <div className="relative">
                                                        {/* Three Dots Button */}
                                                        <button
                                                            className="text-gray-600 hover:text-gray-900 p-1"
                                                            onClick={() => toggleDropdown(trade.id)}
                                                        >
                                                            ⋮
                                                        </button>

                                                        {/* Action Dropdown */}
                                                        {dropdownVisible === trade.id && (
                                                            <div ref={dropdownRef} className="absolute right-0 mt-2 w-24 bg-gray-300 border z-10">
                                                                <button
                                                                    className="block w-full text-left p-1 text-blue-500 hover:bg-gray-100 border"
                                                                    onClick={() => confirmAction('close', trade.id)}
                                                                >
                                                                    Close
                                                                </button>
                                                                {/* <button
                                                                    className="block w-full text-left p-1 text-red-500 hover:bg-gray-100"
                                                                    onClick={() => confirmAction('delete', row.id)}
                                                                >
                                                                    Delete
                                                                </button> */}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            {/* <td className='border border-gray-300'>
                                            <button
                                                className="block w-full text-left p-1 text-red-500 hover:bg-gray-100"
                                                onClick={() => confirmAction('delete', trade.id)}
                                            >
                                                Delete
                                            </button></td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        )}
                    </div>

                    {/* Confirmation Dialog */}
                    {showConfirm.open && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white p-6 rounded shadow-lg">
                                <p>Are you sure you want to {showConfirm.action} this trade?</p>
                                <div className="flex justify-end mt-4">
                                    <button
                                        className="bg-gray-500 text-white p-1 mr-2"
                                        onClick={() => setShowConfirm({ open: false, action: '', tradeId: null })}
                                        disabled={actionLoading} // Disable if action is in progress
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-green-500 text-white p-1"
                                        onClick={() => {
                                            if (showConfirm.action === 'delete') {
                                                handleDelete(showConfirm.tradeId);
                                            } else if (showConfirm.action === 'close') {
                                                handleClose(showConfirm.tradeId);
                                            }
                                        }}
                                        disabled={actionLoading} // Disable if action is in progress
                                    >
                                        {actionLoading ? `${showConfirm.action}ing...` : 'Confirm'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className='w-full bg-white h-[69%] mb-1 rounded-[14px]' >

                <div className='h-[100%] w-full bg-white rounded rounded-tl-[14px] rounded-tr-[14px] p-[10px]' >
                    <div className="overflow-x-auto h-[calc(100%)]">
                        {loading ? ( // Show loader if loading is true
                            <div className="flex justify-center items-center h-full">
                                <div className="loader"></div>
                            </div>
                        ) : ( // Display table when data is loaded
                            <table className="min-w-full divide-y divide-gray-200 border border-gray-300"
                                style={{ maxHeight: '80vh', fontSize: '12px', overflowY: 'auto' }}>
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border border-gray-300 text-left text-xs p-1">Strategy</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Market</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Broker</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Symbol</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">API</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Order Type</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Spot/Limit</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Time Frame</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Long/Short</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Risk Value</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Date/Time</th>
                                        <th className="border border-gray-300 text-left text-xs p-1">Status</th>
                                        {/* <th className="border border-gray-300 text-left text-xs p-1">Status</th> */}
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200" style={{ maxHeight: 'calc(80vh - 50px)', overflowY: 'auto' }}>
                                    {trades.map((trade, index) => (
                                        <tr key={index} className="hover:bg-gray-100">
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.strategy}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.market}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.broker}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.symbol}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.api?.api_name}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.order_type}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.option_strategies}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.time_frame}</td>
                                            {/* <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.long_or_short}</td> */}
                                            <td
                                                className="relative border border-gray-300 text-xs text-center p-1"
                                                onMouseEnter={() => setTooltipVisible(true)}  // Show tooltip on hover
                                                onMouseLeave={() => setTooltipVisible(false)}  // Hide tooltip when mouse leaves
                                            >
                                                {trade.long_or_short} {trade?.swap ? '(s)' : ''}

                                                {/* Tooltip */}
                                                {trade?.swap && tooltipVisible && (
                                                    <div className="absolute mb-2 w-20 p-1 bg-black text-white text-xs rounded-lg shadow-lg">
                                                        {trade.long_or_short === 'Long' ? 'Short' : 'Long'}
                                                    </div>
                                                )}
                                            </td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">{trade.risk_value}</td>
                                            <td className="border border-gray-300 p-1 whitespace-nowrap text-xs">
                                                <DateTimeWithTooltip dateString={trade.created_at} />
                                            </td>

                                            <td className=" p-1 whitespace-nowrap text-xs flex items-center">
                                                <button
                                                    // onClick={() => toggleTradeStatus(trade.id)} // Call toggle function
                                                    className={`py-1 px-3 rounded text-red-500 `}
                                                >
                                                    {trade.status}
                                                </button>

                                            </td>
                                            {/* 
                                                <td className='border border-gray-300'>
                                                    <button
                                                        className="block w-full text-left p-1 text-red-500 hover:bg-gray-100"
                                                        onClick={() => confirmAction('delete', trade.id)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td> 
                                            */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        )}
                    </div>

                    {/* Confirmation Dialog */}
                    {showConfirm.open && (
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white p-6 rounded shadow-lg">
                                <p>Are you sure you want to {showConfirm.action} this trade?</p>
                                <div className="flex justify-end mt-4">
                                    <button
                                        className="bg-gray-500 text-white p-2 rounded mr-2"
                                        onClick={() => setShowConfirm({ open: false, action: '', tradeId: null })}
                                        disabled={actionLoading} // Disable if action is in progress
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-green-500 text-white p-2 rounded"
                                        onClick={() => {
                                            if (showConfirm.action === 'delete') {
                                                handleDelete(showConfirm.tradeId);
                                            } else if (showConfirm.action === 'close') {
                                                handleClose(showConfirm.tradeId);
                                            }
                                        }}
                                        disabled={actionLoading} // Disable if action is in progress
                                    >
                                        {actionLoading ? `${showConfirm.action}ing...` : 'Confirm'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MyOrders;
