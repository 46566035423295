import React, { useState, useEffect } from 'react';

// Function to calculate the relative time (e.g., "6 Days Ago", "1 Day Ago")
const getRelativeTime = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    const intervals = {
        year: 31536000,
        month: 2592000,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
    };

    for (const [unit, secondsInUnit] of Object.entries(intervals)) {
        const count = Math.floor(diffInSeconds / secondsInUnit);
        if (count >= 1) {
            // Determine if it's yesterday or today
            let relativeTime;
            if (unit === 'day') {
                relativeTime = count === 1 ? '1 Day Ago' : `${count} Days Ago`;
            } else {
                relativeTime = `${count} ${unit.charAt(0).toUpperCase() + unit.slice(1)} Ago`;
            }
            return relativeTime.charAt(0).toUpperCase() + relativeTime.slice(1); // Capitalize first letter
        }
    }
    return 'Just Now';
};

// Component to format and display the date with a tooltip
const DateTimeWithTooltip = ({ dateString }) => {
    const [relativeTime, setRelativeTime] = useState('');
    const [fullDateTime, setFullDateTime] = useState('');

    useEffect(() => {
        const date = new Date(dateString);
        setRelativeTime(getRelativeTime(date)); // Calculate relative time
        setFullDateTime(`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`); // Full date-time
    }, [dateString]);

    return (
        <span title={fullDateTime}> {/* Tooltip on hover */}
            {relativeTime}
        </span>
    );
};

export default DateTimeWithTooltip;
