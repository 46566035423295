import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Dashboard.css';
import baseURL from './../url';
import apiClient from './../actions/axiosInterceptor';
import DateTimeWithTooltip from './DateTimeWithTooltip';

const OpenOrders = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    const [activeTrades, setActiveTrades] = useState([]); // State to store trades
    const [trades, setTrades] = useState([]); // State to store trades
    const [error, setError] = useState(null);  // State to handle error
    const [loading, setLoading] = useState(true); // State for loader
    const [showConfirm, setShowConfirm] = useState({ open: false, action: '', tradeId: null }); // State for confirmation dialog
    const [dropdownVisible, setDropdownVisible] = useState(null); // State to track which dropdown is visible
    const [actionLoading, setActionLoading] = useState(false); // State to handle loading for actions
    const [confirmationMessage, setConfirmationMessage] = useState(''); // State for the confirmation message

    const dropdownRef = useRef(null); // Ref to track the dropdown

    const [tooltipVisible, setTooltipVisible] = useState(false);

    // Fetch initial trades data from the API
    const fetchActiveTrades = async () => {
        try {
            const response = await apiClient.get(`${baseURL}/analytics/api/open-order/?status=Active`);
            const sortedTrades = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            console.log(sortedTrades)
            setActiveTrades(sortedTrades);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setError('Failed to fetch trades');
            setLoading(false);
        }
    };

    // Fetch initial trades data from the API
    const fetchTrades = async () => {
        try {
            const response = await apiClient.get(`${baseURL}/analytics/api/open-order/?status=Hold`);
            const sortedTrades = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            console.log(sortedTrades)
            setTrades(sortedTrades);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setError('Failed to fetch trades');
            setLoading(false);
        }
    };


    useEffect(() => {

        const socket = new WebSocket('wss://beta.hedgemarc.ca/ws/trades/');

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log('Received data:', data);

                fetchActiveTrades();
                fetchTrades();
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
                setError('Failed to parse message');
            }
        };

        fetchActiveTrades();
        fetchTrades();

    }, [accessToken]);



    useEffect(() => {
        // Function to close dropdown if clicked outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(null); // Close the dropdown
            }
        };

        // Attach event listener for click outside
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible]);


    // Handle trade closure
    const handleClose = async (tradeId) => {
        setActionLoading(true);
        try {
            await apiClient.patch(`${baseURL}/analytics/api/open-order/?id=${tradeId}`, { status: 'Closed' });
            setTrades(trades.map(trade => trade.id === tradeId ? { ...trade, status: 'Closed' } : trade));
            setShowConfirm({ open: false, action: '', tradeId: null });
            setError(null);
            fetchActiveTrades();
            fetchTrades();
            setConfirmationMessage('Trade closed successfully');  // Set confirmation message on successful closure
        } catch (err) {
            console.error('Error closing trade:', err);
            setError('Failed to close trade');
        } finally {
            setActionLoading(false);
        }
    };
    // Show confirmation dialog
    const confirmAction = (action, tradeId, order_id) => {
        setShowConfirm({ open: true, action, tradeId, order_id });
    };

    // Toggle dropdown visibility for a specific trade
    const toggleDropdown = (tradeId) => {
        setDropdownVisible(dropdownVisible === tradeId ? null : tradeId); // Toggle the visibility of the dropdown for the clicked trade
    };

    return (
        <div className={`h-[100%] ${selectedFilterfullHeightButton === true ? '' : 'pt-[10%]'} transform transition-all duration-500 ease-out translate-y-full opacity-0 animate-slide-up`} >
            <div className='w-full bg-white h-[30%] mb-1 rounded-[14px]' >
                <div className='h-[100%] bg-white rounded rounded-tl-[14px] rounded-tr-[14px] p-[10px]' >
                    <div className="overflow-x-auto h-[100%]">
                        {loading ? ( // Show loader if loading is true
                            <div className="flex justify-center items-center h-full">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border border-gray-300 text-xs p-1">Strategy</th>
                                        <th className="border border-gray-300 text-xs p-1">Market</th>
                                        <th className="border border-gray-300 text-xs p-1">Broker</th>
                                        <th className="border border-gray-300 text-xs p-1">Symbol</th>
                                        <th className="border border-gray-300 text-xs p-1">API Name</th>
                                        <th className="border border-gray-300 text-xs p-1">Order Type</th>
                                        <th className="border border-gray-300 text-xs p-1">Stop/Limit</th>
                                        <th className="border border-gray-300 text-xs p-1">Time Frame</th>
                                        <th className="border border-gray-300 text-xs p-1">Long/Short</th>
                                        <th className="border border-gray-300 text-xs p-1">Risk Value</th>
                                        <th className="border border-gray-300 text-xs p-1">Price</th>
                                        <th className="border border-gray-300 text-xs p-1">Local Currency</th>
                                        <th className="border border-gray-300 text-xs p-1">Date</th>
                                        <th className="border border-gray-300 text-xs p-1">Status</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {activeTrades.map((row) => (
                                        <tr key={row.id} className="hover:bg-gray-100">
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.strategy}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.market}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.broker}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.symbol}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.api?.api_name}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.order_type}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.option_strategies}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.time_frame}</td>
                                            <td
                                                className="relative border border-gray-300 text-xs text-center p-1"
                                                onMouseEnter={() => setTooltipVisible(true)}  // Show tooltip on hover
                                                onMouseLeave={() => setTooltipVisible(false)}  // Hide tooltip when mouse leaves
                                            >
                                                {row.long_or_short} {row?.swap ? '(s)' : ''}

                                                {/* Tooltip */}
                                                {/* {row?.swap && tooltipVisible && (
                                                    <div className="absolute bottom-full mb-2 w-20 p-1 bg-black text-white text-xs rounded-lg shadow-lg">
                                                        {row.long_or_short === 'Long' ? 'Short' : 'Long'} Close
                                                    </div>
                                                )} */}
                                            </td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.risk_value}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.price}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.local_currency}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">
                                                <DateTimeWithTooltip dateString={row.created_at} />
                                            </td>
                                            <td className="border-none p-1 whitespace-nowrap text-xs flex items-center">
                                                {row.status}
                                                {row.status === 'Active' && (
                                                    <div className="relative">
                                                        {/* Three Dots Button */}
                                                        <button
                                                            className="text-gray-600 hover:text-gray-900 p-1"
                                                            onClick={() => toggleDropdown(row.id)}
                                                        >
                                                            ⋮
                                                        </button>

                                                        {/* Action Dropdown */}
                                                        {dropdownVisible === row.id && (
                                                            <div ref={dropdownRef} className="absolute right-0 mt-2 w-24 bg-white shadow-lg rounded-lg border z-10">
                                                                <button
                                                                    className="block w-full text-left p-1 text-blue-500 hover:bg-gray-100"
                                                                    onClick={() => confirmAction('close', row.id, row.order_id)}
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        )}

                                                        {/* Tooltip */}
                                                        {dropdownVisible === row.id && (
                                                            <div className="absolute bottom-full mb-2 right-0 w-48 p-2 bg-black text-white text-xs rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                This will not close the order on the broker. Please check with your broker to close any additional positions.
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            <div className='w-full bg-white h-[69%] mt-1 rounded-[14px]' >
                <div className='h-[100%] bg-white rounded rounded-tl-[14px] rounded-tr-[14px] p-[10px]' >
                    <div className="overflow-x-auto h-[calc(100%)]">
                        {loading ? ( // Show loader if loading is true
                            <div className="flex justify-center items-center h-full">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border border-gray-300 text-xs p-1">Strategy</th>
                                        <th className="border border-gray-300 text-xs p-1">Market</th>
                                        <th className="border border-gray-300 text-xs p-1">Symbol</th>
                                        <th className="border border-gray-300 text-xs p-1">Broker</th>
                                        <th className="border border-gray-300 text-xs p-1">API Name</th>
                                        <th className="border border-gray-300 text-xs p-1">Order Type</th>
                                        <th className="border border-gray-300 text-xs p-1">Stop/Limit</th>
                                        <th className="border border-gray-300 text-xs p-1">Time Frame</th>
                                        <th className="border border-gray-300 text-xs p-1">Long/Short</th>
                                        <th className="border border-gray-300 text-xs p-1">Risk Value</th>
                                        <th className="border border-gray-300 text-xs p-1">Price</th>
                                        <th className="border border-gray-300 text-xs p-1">Local Currency</th>
                                        <th className="border border-gray-300 text-xs p-1">Executed Date</th>
                                        <th className="border border-gray-300 text-xs p-1">Closed Date</th>
                                        <th className="border border-gray-300 text-xs p-1">Status</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {trades.map((row) => (
                                        <tr key={row.id} className="hover:bg-gray-100">
                                            <td className="border border-gray-300 text-center text-xs p-1">{row.strategy}</td>
                                            <td className="border border-gray-300 text-center text-xs p-1">{row.market}</td>
                                            <td className="border border-gray-300 text-center text-xs p-1">{row.symbol}</td>
                                            <td className="border border-gray-300 text-center text-xs p-1">{row.broker}</td>
                                            <td className="border border-gray-300 text-center text-xs p-1">{row.api?.api_name}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.order_type}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.option_strategies}</td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.time_frame}</td>
                                            {/* <td className="border border-gray-300 text-xs text-center p-1">{row.long_or_short} {row?.swap ? ('(s)') : ''}</td> */}
                                            <td
                                                className="relative border border-gray-300 text-xs text-center p-1"
                                                onMouseEnter={() => setTooltipVisible(true)}  // Show tooltip on hover
                                                onMouseLeave={() => setTooltipVisible(false)}  // Hide tooltip when mouse leaves
                                            >
                                                {row.long_or_short} {row?.swap ? '(s)' : ''}

                                                {/* Tooltip */}
                                                {row?.swap && tooltipVisible && (
                                                    <div className="absolute bottom mb-2 w-20 p-1 bg-black text-white text-xs rounded-lg shadow-lg">
                                                        {row.long_or_short === 'Long' ? 'Short' : 'Long'} Close
                                                    </div>
                                                )}
                                            </td>
                                            <td className="border border-gray-300 text-xs text-center p-1">{row.risk_value}</td>
                                            <td className="border border-gray-300 text-center text-xs p-1">{row.price}</td>
                                            <td className="border border-gray-300 text-center text-xs p-1">{row.local_currency}</td>
                                            <td className="border border-gray-300 text-center text-xs p-1">
                                                <DateTimeWithTooltip dateString={row.created_at} /> {/* Date with tooltip */}
                                            </td>
                                            <td className="border border-gray-300 text-center text-xs p-1">
                                                {row.closed_date ? (<DateTimeWithTooltip dateString={row.closed_date} />) : ''}
                                            </td>
                                            <td className="p-1 whitespace-nowrap text-xs flex items-center">
                                                {row.status}
                                                {row.status === 'Active' && (
                                                    <div className="relative">
                                                        {/* Three Dots Button */}
                                                        <button
                                                            className="text-gray-600 hover:text-gray-900 p-1"
                                                            onClick={() => toggleDropdown(row.id)}
                                                        >
                                                            ⋮
                                                        </button>

                                                        {/* Action Dropdown */}
                                                        {dropdownVisible === row.id && (
                                                            <div ref={dropdownRef} className="absolute right-0 mt-2 w-24 bg-white z-10">
                                                                <button
                                                                    className="block w-full text-left p-1 text-blue-500 hover:bg-gray-100 "
                                                                    onClick={() => confirmAction('close', row.id, row.order_id)}
                                                                >
                                                                    Close
                                                                </button>
                                                                {/* <button
                                                                    className="block w-full text-left p-1 text-red-500 hover:bg-gray-100"
                                                                    onClick={() => confirmAction('delete', row.id)}
                                                                >
                                                                    Delete
                                                                </button> */}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>


                {/* Confirmation Dialog */}
                {showConfirm.open && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded shadow-lg">
                            <p>This will not close the order on the broker. Please check with your broker to close any additional positions.</p>
                            <p>Are you sure you want to {showConfirm.action} this trade?</p>

                            <h1 className='text-left justify-start font-bold' >Order Id : {showConfirm.order_id}</h1>
                            <div className="flex justify-end mt-4">
                                <button
                                    className="bg-gray-500 text-white p-2 mr-2 rounded"
                                    onClick={() => setShowConfirm({ open: false, action: '', tradeId: null })}
                                    disabled={actionLoading} // Disable if action is in progress
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-green-500 text-white p-2 rounded"
                                    onClick={() => {
                                        if (showConfirm.action === 'close') {
                                            handleClose(showConfirm.tradeId);
                                        }
                                    }}
                                    disabled={actionLoading} // Disable if action is in progress
                                >
                                    {actionLoading ? `${showConfirm.action}ing...` : 'Confirm'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Confirmation Dialog */}
                {confirmationMessage.open && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded shadow-lg">
                            <p>Please verify with your broker. The order has only been closed internally on our end and not with the broker.</p>
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
};

export default OpenOrders;
