import React, { useEffect } from 'react';

const CryptoCharts = () => {
    useEffect(() => {
        const widgetContainer = document.getElementById('tradingview-screener-container__widget');

        // Check if the widget container exists and has no existing scripts
        if (widgetContainer && !widgetContainer.querySelector('script')) {
            // Clear any existing inner HTML
            widgetContainer.innerHTML = '';

            // Create a new script element
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
            script.async = true;

            // Set the inner HTML with the widget configuration
            script.innerHTML = JSON.stringify({
                "width": "100%",
                "height": "100%",
                "defaultColumn": "overview",
                "screener_type": "crypto_mkt",
                "displayCurrency": "USD",
                "colorTheme": "light",
                "locale": "en",
                "isTransparent": true
            });

            // Append the script to the widget container
            widgetContainer.appendChild(script);
        }


    }, []);

    return (
        <div className="tradingview-widget-container h-full animated bg-white">
            <div id="tradingview-screener-container__widget" style={{ height: "100%" }}></div>

        </div>
    );
};

export default CryptoCharts;
