import React, { useState, useEffect } from 'react';
import apiClient from '../actions/axiosInterceptor';
import DateTimeWithTooltip from './DateTimeWithTooltip';
import { FaTrash } from 'react-icons/fa';
import '../assets/css/Dashboard.css';
import baseURL from './../url';
import Disclaimers from './../assets/image/information.png';

const Sidebar = ({ isOpen, onClose, user }) => {
    const [userQueryData, setUserQueryData] = useState([]);
    const [userSelectedQueryData, setUserSelectedQueryData] = useState(null); // Store selected query data
    const [userSelectedQueryMessageData, setUserSelectedQueryMessageData] = useState(null); // Store selected query data
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sloading, setSLoading] = useState(false);
    const [smloading, setSMLoading] = useState(false);
    const [queryId, setQueryId] = useState(null);
    const [qmessage, setQmessage] = useState(null);
    const [error, setError] = useState('');

    const [searchTerm, setSearchTerm] = useState('');


    const handleSidebarToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('query', query);
        formData.append('description', description);
        if (file) {
            formData.append('file', file);
        }

        try {
            setLoading(true);
            const response = await apiClient.post('/support/api/query', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                console.log("Query submitted successfully!");
                setQuery('');
                setDescription('');
                setFile(null);
                setIsSidebarOpen(!isSidebarOpen);
            } else {
                console.error("Failed to submit the query.");
            }
        } catch (error) {
            console.error("Error occurred while submitting the query:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const fetchUserData = async () => {
        setLoading(true);
        setUserSelectedQueryData(null);

        try {
            const response = await apiClient.get('/support/api/query');
            console.log(response)
            setUserQueryData(response.data.support);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const handleSelectQuery = async (support_id) => {
        setQueryId(support_id);
        setQmessage('');
        setSLoading(true);
        try {
            const response = await apiClient.get(`/support/api/view/query/${support_id}`);
            console.log(response);
            setUserSelectedQueryData(response.data.support); // Set selected query data
            fetchUserQueryMessagesData(support_id)
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserSelectedQueryData(null);
        } finally {
            setSLoading(false);
        }
    };


    const handleChangeMessage = async (message) => {
        setQmessage(message);
    };

    const validateFields = () => {
        if (!qmessage) {
            setError('All fields are required.');
            return false;
        }
        setError(''); // Clear error if validation is successful
        return true;
    };


    const fetchUserQueryMessagesData = async (support_id) => {
        setLoading(true);
        try {
            const response = await apiClient.get(`/support/api/view/message/${support_id}`);
            console.log(response)
            setUserSelectedQueryMessageData(response.data.support);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };



    // Function to handle form submission
    const handleSendMessage = async (event) => {
        event.preventDefault();
        setSMLoading(true);

        if (!validateFields()) {
            setSMLoading(false); // Stop loading if validation fails
            return;
        }
        // Create the form data to send
        const formData = {
            message: qmessage,
        };

        try {
            const response = await apiClient.post(`/support/api/add/message/${queryId}`, {
                formData
            });

            const data = await response.data;
            setQmessage('');
            fetchUserQueryMessagesData(queryId);
            console.log('API response:', data);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        finally {
            setSMLoading(false)
        }
    };


    useEffect(() => {

        const socket = new WebSocket('wss://beta.hedgemarc.ca/ws/support/');
        socket.onopen = () => {
            console.log('WebSocket connected to wss://beta.hedgemarc.ca/ws/support/');
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log('Received data:', data);

                fetchUserData();
                if (userSelectedQueryData) {
                    fetchUserQueryMessagesData(userSelectedQueryData);
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);

            }
        };

        // Handle WebSocket errors
        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setError('WebSocket connection error');
        };


        // Show when the WebSocket is closed
        socket.onclose = (event) => {
            console.log(`WebSocket closed: Code=${event.code}, Reason=${event.reason}`);
        };

        fetchUserData();
        if (userSelectedQueryData) {
            fetchUserQueryMessagesData(userSelectedQueryData);
        }

    }, []);


    // Filter user queries based on search term
    const filteredQueries = userQueryData.filter((item) =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [showDetails, setShowDetails] = useState(false);

    // Toggle visibility of user details on click
    const handleToggleDetails = () => {
        setShowDetails(!showDetails);
    };

    // State to manage expansion
    const [isExpanded, setIsExpanded] = useState(false);

    // Toggle expand/collapse state
    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div
            className={`fixed top-0 right-0 h-full bg-white text-gray-800 p-5 transition-transform duration-300 ease-in-out shadow-md ${isOpen ? 'translate-x-0' : 'translate-x-full'
                }`}
            style={{ width: '350px' }}
        >
            {/* Close button */}
            <div className='flex justify-between'>
                <h1 className="text-2xl font-bold">Support</h1>
                <button
                    className="top-5 bg-orange-500 rounded-2xl text-white text-l border p-2 border-grey-800 rounded"
                    onClick={handleSidebarToggle}
                >
                    {isSidebarOpen ? <p>Close</p> : <p>Add Query</p>}
                </button>
                <button
                    className="top-5 text-grey-800 text-xl"
                    onClick={onClose}
                >
                    &times;
                </button>
            </div>

            {/* Content of the sidebar */}
            <div className="mt-5 h-[100%]">
                {isSidebarOpen ? (
                    <form onSubmit={handleSubmit}>
                        <input
                            type='text'
                            className='border w-full rounded p-2 border-grey-800 m-1'
                            placeholder='Add Your Query'
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            disabled={loading}
                            required
                        />
                        <textarea
                            className='border w-full rounded p-2 border-grey-800 m-1 h-[500px]'
                            placeholder="Describe your issue"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            disabled={loading}
                            required
                        />
                        <input
                            type='file'
                            accept='.jpg,.png,.jpeg'
                            className='border w-full rounded p-2 border-grey-800 m-1'
                            onChange={handleFileChange}
                            disabled={loading}
                        />
                        <button
                            type='submit'
                            className='border bg-orange-500 text-white w-full rounded p-2 border-grey-800 m-1'
                            disabled={loading}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                ) : (
                    userSelectedQueryData ? (
                        sloading ? (
                            <div className="flex justify-center items-center h-full">
                                <div className="loader"></div>
                            </div>
                        ) : (

                            <div className="mt-5 h-[89%] overflow-scroll pb-15 mb-15">
                                <h2 className="text-xm font-bold overflow-scroll cursor-pointer" onClick={() => setUserSelectedQueryData(null)}> &#8617;	 {userSelectedQueryData.title.trim()} <span className='text-xs font-normal'> {<DateTimeWithTooltip dateString={userSelectedQueryData.date_time} />}</span></h2>

                                {user?.role === 'admin' && (

                                    <div className='flex items-center justify-between border bg-gray-200 p-2 rounded-lg cursor-pointer' onClick={handleToggleExpand}>
                                        <div className='flex items-center'>
                                            <div className="bg-orange-500 border border-orange-500 p-2 rounded-full w-6 h-6 flex items-center justify-center text-white cursor-pointer">
                                                {userSelectedQueryData.user?.first_name.charAt(0).toUpperCase()}
                                            </div>
                                            &nbsp;
                                            {userSelectedQueryData.user?.first_name}    {userSelectedQueryData.user?.last_name}
                                        </div>
                                        <div className="flex flex-col self-center items-center justify-center">
                                            {isExpanded ? (<span>&#8964;</span>) : (<span>&#8963;</span>)}
                                        </div>
                                    </div>
                                )}

                                {isExpanded && (
                                    <div className="mt-2 p-4 mb-2 border bg-gray-100 rounded-lg">
                                        <p className='text-xs'><strong>User Id:</strong> {userSelectedQueryData.user?.id}</p>
                                        <p className='text-xs'><strong>Full Name:</strong> {userSelectedQueryData.user?.first_name} {userSelectedQueryData.user?.last_name}</p>
                                        <p className='text-xs'><strong>Email:</strong> {userSelectedQueryData.user?.email}</p>
                                        <p className='text-xs'><strong>Mobile:</strong> {userSelectedQueryData.mobile || ''}</p>
                                        {/* You can add more user details here */}
                                    </div>
                                )}

                                <p className='text-justify text-xs' >{userSelectedQueryData.description}</p>
                                {userSelectedQueryData.file && (
                                    <div>
                                        <a
                                            href={`${baseURL}${userSelectedQueryData.file}`} // Link to the file if available
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 underline"
                                        >
                                            View attached file
                                        </a>
                                    </div>
                                )}
                                <div className="chat-container">
                                    {userSelectedQueryMessageData && userSelectedQueryMessageData.map((message, index) => (
                                        <div
                                            key={index}
                                            className={`message ${message.user.id === user.id ? 'message-right' : 'message-left'}`}
                                        >
                                            <div className="message-content">
                                                <p className='text-xm'>{message.message}</p>
                                                <span className={`${message.user.id === user.id ? 'message-right-date' : 'message-left-date'}`} ><DateTimeWithTooltip dateString={message.joined_date} /></span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <span className='mb-2 pb-5 h-10'>&nbsp;</span>
                                <div className='flex items-center border w-full p-2 fixed bottom-0 left-0 bg-white'>
                                    <input className='border w-[85%] p-2 m-2' type='text' name='message' value={qmessage}
                                        onChange={(e) => handleChangeMessage(e.target.value)}
                                        placeholder="Type your message" required />
                                    <button onClick={handleSendMessage} className='bg-orange-500 text-white p-2 rounded m-2 hover:bg-orange-600'>
                                        {smloading ? (
                                            <svg
                                                className="w-5 h-5 mr-3 text-white animate-spin"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                ></path>
                                            </svg>
                                        ) : "Send"}
                                    </button>
                                </div>
                            </div>
                        )
                    ) : (

                        <div>
                            {/* Search and list of queries */}
                            <div>
                                <input className='w-full border p-2 rounded border-grey-800' type='search' placeholder='Search..' value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                            <div>
                                {filteredQueries.map((item, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSelectQuery(item.support_id)}
                                        className='w-full flex p-2 border mt-2 justify-between hover:rounded-[10px] cursor-pointer hover:border-black hover:bg-gray-100'
                                    >
                                        <div>
                                            <h2 className='text-s cursor-pointer text-grey-600 hover:font-bold '>
                                                {item.title.trim()}
                                            </h2>
                                            <p className='text-xs'>{item.description.slice(0, 55)}</p>
                                        </div>
                                        <div className='flex flex-col justify-end items-end'>
                                            <span><FaTrash className="text-red-600 hover:text-red-800 focus:outline-none cursor-pointer" aria-label="Delete" /></span>
                                            <span className='text-xs text-end' style={{ fontSize: "8px" }}>
                                                {<DateTimeWithTooltip dateString={item.date_time} />}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                )}
            </div>

        </div>
    );
};

export default Sidebar;
