import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Dashboard.css';
import apiClient from './../actions/axiosInterceptor';
import baseURL from './../url';

const AddApi = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    // State to manage the selected button
    const [selectedView, setSelectedView] = useState('addApi');
    const [selectedButton, setSelectedButton] = useState('crypto');
    const [loading, setLoading] = useState(false);
    console.log(user)
    // State to manage form inputs
    const [apiName, setApiName] = useState('');
    const [broker, setBroker] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [status, setStaus] = useState('Active');

    const [secretKey, setSecretKey] = useState('');
    const [error, setError] = useState('');

    const [trades, setTrades] = useState([]); // State to store trades

    const [loadingApi, setApiLoading] = useState(false);

    const [showConfirm, setShowConfirm] = useState({ open: false, action: '', tradeId: null }); // State for confirmation dialog
    const [dropdownVisible, setDropdownVisible] = useState(null); // State to track which dropdown is visible
    const [actionLoading, setActionLoading] = useState(false); // State to handle loading for actions

    const dropdownRef = useRef(null); // Ref to track the dropdown


    const fetchSavedApis = async () => {
        try {
            const response = await apiClient.get(`/analytics/api/create-api-integration/`);
            const sortedTrades = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setTrades(sortedTrades);
            setApiLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setApiLoading(false);
        }
    };


    // Function to handle button clicks (Forex, Crypto, Stock)
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    const validateFields = () => {
        if (!apiName || !broker || !apiKey || !secretKey) {
            setError('All fields are required.');
            return false;
        }
        setError(''); // Clear error if validation is successful
        return true;
    };


    // Function to handle form submission
    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        if (!validateFields()) {
            setLoading(false); // Stop loading if validation fails
            return;
        }
        // Create the form data to send
        const formData = {
            api_name: apiName, // change to snake_case
            broker, // remains the same
            api_key: apiKey, // change to snake_case
            secret_key: secretKey, // change to snake_case
            api_type: selectedButton, // change to snake_case
            user: user?.email,
            status
        };

        try {
            const response = await apiClient.post('/analytics/api/create-api-integration/', {
                formData
            });

            setError('Successflly Saved.');
            console.log(response)
            const data = await response.data;
            setError(data.message);
            console.log('API response:', data);
            setApiName('');
            setBroker('');
            setApiKey('');
            setSecretKey('');
            setSelectedView('myApi')
        } catch (error) {
            console.error('Error submitting form:', error);
            setError('Error submitting form:', error);

        }
        finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        if (selectedView === 'myApi') {
            setApiLoading(true);
            fetchSavedApis();
        }
    }, [selectedView]);

    useEffect(() => {
        // Function to close dropdown if clicked outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(null); // Close the dropdown
            }
        };

        // Attach event listener for click outside
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible]);

    // Handle trade deletion
    const handleDelete = async (tradeId) => {
        setActionLoading(true); // Set loading to true
        try {
            await apiClient.delete(`/analytics/api/create-api-integration/?id=${tradeId}`);
            setTrades(trades.filter(trade => trade.id !== tradeId)); // Remove deleted trade from UI
            setShowConfirm({ open: false, action: '', tradeId: null }); // Close confirmation
        } catch (err) {
            console.error('Error deleting trade:', err);
            setError('Failed to delete trade');
        } finally {
            setActionLoading(false); // Set loading back to false
        }
    };

    // Handle trade closure
    const handleClose = async (tradeId) => {
        setActionLoading(true); // Set loading to true
        try {
            await apiClient.patch(`/analytics/api/create-api-integration/?id=${tradeId}`, { status: 'Closed' });
            setTrades(trades.map(trade => trade.id === tradeId ? { ...trade, status: 'Closed' } : trade)); // Update trade status in UI
            setShowConfirm({ open: false, action: '', tradeId: null }); // Close confirmation
        } catch (err) {
            console.error('Error closing trade:', err);
            setError('Failed to close trade');
        } finally {
            setActionLoading(false); // Set loading back to false
        }
    };

    // Show confirmation dialog
    const confirmAction = (action, tradeId) => {
        setShowConfirm({ open: true, action, tradeId });
    };

    // Toggle dropdown visibility for a specific trade
    const toggleDropdown = (tradeId) => {
        setDropdownVisible(dropdownVisible === tradeId ? null : tradeId); // Toggle the visibility of the dropdown for the clicked trade
    };

    return (
        <div className={`h-[100%] ${selectedFilterfullHeightButton === true ? '' : 'pb-[10%]'} transform transition-all duration-500 ease-out translate-y-full opacity-0 animate-slide-down`} >
            <div className='h-[100%] bg-white rounded rounded-bl-[14px] rounded-br-[14px] p-[10px]'>
                <div className='flex' >
                    <div
                        className={`flex ${selectedView === 'addApi' ? 'bg-black text-white' : 'bg-[#E5E5E5]'}  p-3 m-2 rounded-[10px] w-[20%] cursor-pointer`}
                        onClick={() => setSelectedView('addApi')}
                    >
                        <h3 className='w-[50%] text-sm'>Add API</h3>
                    </div>
                    <div
                        className={`flex ${selectedView === 'myApi' ? 'bg-black text-white' : 'bg-[#E5E5E5]'} p-3 m-2 rounded-[10px] w-[20%] cursor-pointer`}
                        onClick={() => setSelectedView('myApi')}
                    >
                        <h3 className='w-[50%] text-sm'>My API</h3>
                    </div>
                    {/* <div
                        className='flex bg-[red] p-3 m-2 rounded-[10px] w-[20%] cursor-pointer'
                        onClick={handleLogout}
                    >
                        <h3 className='w-[50%] text-sm text-white'>Log Out</h3>
                    </div> */}
                </div>
                {selectedView === 'addApi' ? (
                    <div className='h-[calc(86%)]'>
                        <div className='h-[calc(100%)]'>
                            <div className='flex border-b border-gray-500 align-center'>
                                <h1 style={{ fontSize: "25px", fontWeight: "bold", padding: "10px", width: "25%" }} className='pl-2'>Add Api</h1>
                                <p style={{ fontSize: "12px", display: "flex", alignItems: "center", backgroundColor: "#79609F", color: "white", width: "75%", margin: "10px", justifyContent: "center" }}>
                                    Your API Key is securely encrypted and will be automatically deleted if your account remains inactive for 2 weeks
                                </p>
                            </div>
                            <div className='flex align-center h-[calc(100%-2rem)]'>
                                <div style={{ width: "25%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }} className='border-r border-gray-500' >
                                    <div style={{ padding: "10px", width: "100%", justifyContent: "flex-start" }} className='flex flex-col align-start'>
                                        <button
                                            className={`border border-gray-500 p-3 m-2 ${selectedButton === 'forex' ? 'bg-gray-500 text-white' : ''}`}

                                        >
                                            Forex API
                                        </button>
                                        <button
                                            className={`border border-gray-500 p-3 m-2 ${selectedButton === 'crypto' ? 'bg-black text-white' : ''}`}
                                            onClick={() => handleButtonClick('crypto')}
                                        >
                                            Crypto API
                                        </button>
                                        <button
                                            className={`border border-gray-500 p-3 m-2 ${selectedButton === 'stock' ? 'bg-gray-500 text-white' : ''}`}

                                        >
                                            Stock API
                                        </button>
                                    </div>
                                    <div style={{ padding: "10px", width: "100%", justifyContent: "center", alignItems: "center" }} className='flex flex-col align-start'>
                                        <select className='border' style={{ width: "93%", padding: "10px", cursor: "pointer" }}
                                            value={status}
                                            onChange={(e) => setStaus(e.target.value)}
                                        >
                                            <option>Active</option>
                                            {/* <option>Hold</option>
                                            <option>Closed</option> */}
                                        </select>
                                    </div>
                                </div>

                                <div style={{ fontSize: "12px", width: "75%", margin: "10px" }}>
                                    {error && <p className="text-red-500">{error}</p>}
                                    <form className='h-full flex flex-col justify-between items-end' onSubmit={handleSubmit}>
                                        <div className='w-full'>
                                            <div>
                                                <input required
                                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                                    placeholder='Enter API Name'
                                                    value={apiName}
                                                    onChange={(e) => setApiName(e.target.value)}
                                                />
                                                <select required
                                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                                    value={broker}
                                                    onChange={(e) => setBroker(e.target.value)}
                                                >
                                                    <option>Select Broker</option>
                                                    <option value="Binance" >Binance</option>
                                                    <option value="Forex.com" >Forex.com</option>
                                                    <option value="Capital.com" >Capital.com</option>
                                                </select>
                                            </div>
                                            <div>
                                                <input required
                                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                                    placeholder='Enter API Key'
                                                    value={apiKey}
                                                    onChange={(e) => setApiKey(e.target.value)}
                                                />
                                                <input required
                                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                                    placeholder='Enter Secret Key'
                                                    value={secretKey}
                                                    onChange={(e) => setSecretKey(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <button type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2'>

                                            <div className="flex items-center justify-center">
                                                {loading ? (
                                                    <svg
                                                        className="w-5 h-5 mr-3 text-white animate-spin"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        ></circle>
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                        ></path>
                                                    </svg>
                                                ) : null}
                                                {loading ? "Submiting..." : "Submit"}
                                            </div>

                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='h-[calc(90%)]'>
                        <div className='overflow-x-auto h-[calc(100%)]'>
                            {loadingApi ? ( // Show loader if loading is true
                                <div className="flex justify-center items-center h-full">
                                    <div className="loader"></div>
                                </div>
                            ) : ( // Display table when data is loaded
                                <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                                    <thead>
                                        <tr className="bg-gray-200">
                                            <th className="border border-gray-300  text-left text-sm p-2">Api Type</th>
                                            <th className="border border-gray-300  text-left text-sm p-2">Api Name</th>
                                            <th className="border border-gray-300  text-left text-sm p-2">Broker</th>
                                            <th className="border border-gray-300  text-left text-sm p-2">Api Key</th>
                                            <th className="border border-gray-300  text-left text-sm p-2">Secret Key</th>
                                            <th className="border border-gray-300  text-left text-sm p-2">Status</th>
                                            <th className="border border-gray-300  text-left text-sm p-2">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {trades.map((trade, index) => (
                                            <tr key={index} className="hover:bg-gray-100">
                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{trade.api_type}</td>
                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{trade.api_name}</td>
                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{trade.broker}</td>
                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{trade.api_key}</td>
                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">{trade.secret_key}</td>
                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm">
                                                    {trade.status ? (trade.status) : (<p>&nbsp;</p>)}
                                                </td>

                                                <td className="border border-gray-300 p-2 whitespace-nowrap text-sm ">

                                                    {trade.status === 'Active' && (
                                                        <div className="relative">
                                                            {/* Three Dots Button */}
                                                            <button
                                                                className="text-gray-600 hover:text-gray-900 p-2" style={{ zIndex: "-1" }}
                                                                onClick={() => toggleDropdown(trade.id)}
                                                            >
                                                                ⋮
                                                            </button>

                                                            {/* Action Dropdown */}
                                                            {dropdownVisible === trade.id && (
                                                                <div ref={dropdownRef} className="absolute right-0 mt-2 w-24 bg-white shadow-lg rounded-lg" style={{ zIndex: "9999" }}>
                                                                    {/* <button
                                                                        className="block w-full text-left p-2 text-blue-500 hover:bg-gray-100"
                                                                        onClick={() => confirmAction('close', trade.id)}
                                                                    >
                                                                        Close
                                                                    </button> */}
                                                                    <button
                                                                        className="block w-full text-left p-2 text-red-500 hover:bg-gray-100"
                                                                        onClick={() => confirmAction('delete', trade.id)}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            )}

                                                        </div>
                                                    )}

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                            {/* Confirmation Dialog */}
                            {showConfirm.open && (
                                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                                    <div className="bg-white p-6 rounded shadow-lg">
                                        <p>Are you sure you want to {showConfirm.action} this trade?</p>
                                        <div className="flex justify-end mt-4">
                                            <button
                                                className="bg-gray-500 text-white p-2 mr-2"
                                                onClick={() => setShowConfirm({ open: false, action: '', tradeId: null })}
                                                disabled={actionLoading} // Disable if action is in progress
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                className="bg-green-500 text-white p-2"
                                                onClick={() => {
                                                    if (showConfirm.action === 'delete') {
                                                        handleDelete(showConfirm.tradeId);
                                                    } else if (showConfirm.action === 'close') {
                                                        handleClose(showConfirm.tradeId);
                                                    }
                                                }}
                                                disabled={actionLoading} // Disable if action is in progress
                                            >
                                                {actionLoading ? `${showConfirm.action}ing...` : 'Confirm'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                )}
            </div>
        </div>
    );
};

export default AddApi;
