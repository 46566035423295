import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Admin/NavBar';
import BottomMenu from '../../components/BottomMenu';
import Home from '../../components/Admin/Home';
import Push from '../../components/Admin/Push';
import RightComponent from '../../components/RightComponent';
import LeftComponent from '../../components/LeftComponent';

import Sidebar from '../../components/Sidebar';

const Dashboard = ({ user, accessToken, handleLogout }) => {
    const [selectedButton, setSelectedButton] = useState(null);

    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const openSidebar = () => setSidebarOpen(true);
    const closeSidebar = () => setSidebarOpen(false);

    useEffect(() => {
        const savedButton = localStorage.getItem('selectedButton');
        if (savedButton) {
            setSelectedButton(savedButton);
        }
    }, []);

    const handleActiveButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        localStorage.setItem('selectedButton', buttonName); // Save to localStorage
    };

    const [selectedFilterfullHeightButton, setSelectedFilterfullHeightButton] = useState(false);

    useEffect(() => {
        const savedButtons = localStorage.getItem('selectedFilterfullHeightButtons');
        if (savedButtons) {
            setSelectedFilterfullHeightButton(selectedFilterfullHeightButton); // Parse the JSON string into an array
        }
    }, [selectedFilterfullHeightButton]);

    const handleActiveFilterfullHeightButtonClick = (buttonName) => {
        setSelectedFilterfullHeightButton(!selectedFilterfullHeightButton);
        localStorage.setItem('selectedFilterfullHeightButtons', !selectedFilterfullHeightButton); // Save to localStorage
    };


    const renderComponent = () => {
        switch (selectedButton) {
            case 'home':
                return <Home user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
            case 'push':
                return <Push user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
            default:
                return <Home user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
        }
    };



    return (
        <div style={{ overflow: "hidden" }} className='h-screen overflow-hidden' >

            <Navbar user={user} accessToken={accessToken} handleLogout={handleLogout} handleActiveButtonClick={handleActiveButtonClick} openSidebar={openSidebar} />
            <section className=' h-[100%] flex w-full' >
                {/* <div className='w-14 h-[100%] bg-white  mt-1.5' style={{ borderTopRightRadius: "4px" }} >
                    <LeftComponent user={user} accessToken={accessToken} handleLogout={handleLogout} handleActiveButtonClick={handleActiveButtonClick} />
                </div> */}
                <div className='w-full h-[100%] mr-2' >
                    <div className={`w-full h-[calc(100%)] m-1.5`}>
                        {renderComponent()}
                    </div>
                    {/* <div className='w-full bg-white h-12 m-1.5 rounded'>
                        <BottomMenu user={user} accessToken={accessToken} handleLogout={handleLogout} handleActiveButtonClick={handleActiveButtonClick} selectedButton={selectedButton} handleActiveFilterfullHeightButtonClick={handleActiveFilterfullHeightButtonClick} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />
                    </div> */}
                </div>
                {/* <div className='w-[25%]  h-[100%] bg-white ml-1 mt-1.5 rounded' >
                    <RightComponent user={user} accessToken={accessToken} handleLogout={handleLogout} />
                </div> */}

                <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} user={user} />
            </section>

        </div>
    );
};

export default Dashboard;
