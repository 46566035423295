import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import apiClient from '../actions/axiosInterceptor';
// import baseURL from './../url';
import Disclaimers from './../assets/image/information.png';
import Wifi from './../assets/image/wifi.png';
import WifiDisconected from './../assets/image/wi-fi-disconnected.png';
import APIMANAGEMENT from './../assets/image/cloud-coding-svgrepo-com.svg';
import { FiBook } from 'react-icons/fi'

const NavBar = ({ user, accessToken, handleLogout, handleActiveButtonClick, openSidebar }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [result, setResult] = useState('');
    const [showPopup, setShowPopup] = useState(false); // Control popup visibility
    const [formData, setFormData] = useState({
        first_name: userData?.profile?.user.first_name || '',
        last_name: userData?.profile?.user.last_name || '',
        mobile: userData?.profile?.mobile_no || '',
    });

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        handleActiveButtonClick(buttonName);
        setActiveButton(buttonName);
    };

    const [showDropdown, setShowDropdown] = useState(false); // State to track dropdown visibility

    // Toggle dropdown visibility
    const handleSettingButtonClick = () => {
        setShowDropdown((prev) => !prev);
    };

    // Handle menu options
    const handleOptionClick = (buttonName) => {
        setActiveButton(buttonName);
        if (buttonName === 'Help') {
            openSidebar();
        }
        else {
            handleActiveButtonClick(buttonName);
        }
        if (buttonName === 'add_api' || buttonName === 'Billings' || buttonName === 'Contacts' || buttonName === 'Help') {
            setShowDropdown((prev) => !prev);
        }

    };

    // Fetch user data if access token is available
    const fetchUserData = async () => {
        try {
            const response = await apiClient.get('/auth/api/me/');
            console.log(response.data)
            setUserData(response.data); // Assuming response contains user data
            setFormData({
                first_name: response?.data.profile.user.first_name || '',
                last_name: response?.data.profile.user.last_name || '',
                mobile: response?.data.profile.mobile_no || '',
            })
        } catch (error) {
            console.error('Error fetching user data:', error);
            if (error.response && error.response.status === 401) {
                handleLogout(); // Log out the user if unauthorized
            }
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [accessToken]); // Add `handleLogout` as a dependency if it's coming from props or state


    const handleReload = () => {
        window.location.reload();
    };

    // Function to toggle fullscreen when the second image is clicked
    const handleFullscreen = () => {
        const doc = document.documentElement;
        if (!document.fullscreenElement) {
            doc.requestFullscreen().catch((err) => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const [isOnline, setIsOnline] = useState(navigator.onLine); // Initialize state with online status

    useEffect(() => {
        // Function to update the online status
        const updateOnlineStatus = () => {
            setIsOnline(navigator.onLine);
        };

        // Add event listeners to monitor online/offline status changes
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        // Cleanup event listeners when the component unmounts
        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    const handleUserNameClick = () => {
        setShowPopup(true); // Show the popup when name icon is clicked
        setResult('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable inputs and button

        try {
            console.log('Form data:', formData);

            // Send API request
            const response = await apiClient.post('/auth/api/me/', formData); // No need to wrap formData in another object

            if (response.status === 200) {
                // Assuming the response contains the updated user data
                fetchUserData();

                // Close the popup after successful submission
                // setShowPopup(false);

                // Optionally show a success message
                setResult('Form submitted successfully');
            } else {
                // Handle non-200 responses (e.g., 400, 500)
                setResult('Failed to submit form. Please try again.');
            }

        } catch (error) {
            // Handle errors (e.g., network issues, server errors)
            console.error('Error submitting form:', error);
            setResult('An error occurred. Please try again.');
        } finally {
            // Re-enable the inputs and button after submission (successful or not)
            setIsSubmitting(false);
        }
    };

    const closePopup = () => {
        setShowPopup(false); // Close the popup when clicking outside or cancel
    };

    return (
        <nav className="bg-white text-black shadow-md h-12">
            <div className="flex flex-col w-full h-12 max-md:max-w-full">
                <div className="flex flex-wrap items-start w-full h-12 max-md:max-w-full">
                    <div className="flex  flex-col grow shrink min-w-[240px] w-full max-md:max-w-full">
                        <div className="flex  flex-col pb-24 min-h-[142px] max-md:pb-24 max-md:max-w-full">
                            <div className="flex flex-col w-full  max-md:max-w-full">
                                <div className="flex items-start w-full h-12 max-md:max-w-full">
                                    <div className="flex items-start w-full h-12 max-md:max-w-full">
                                        <div className="flex  justify-center items-center bg-white h-12 min-w-12" onClick={handleUserNameClick}>
                                            <div className="bg-orange-500 border border-orange-500 p-2 rounded-full w-8 h-8 flex items-center justify-center text-white cursor-pointer">
                                                {user?.first_name.charAt(0).toUpperCase()}
                                            </div>
                                        </div>
                                        <div className="flex items-center px-1  bg-white h-12 ">
                                            <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                                        </div>
                                        <div className="flex  justify-center items-center bg-white h-12 ">

                                            <div className="flex  justify-center items-center my-auto h-12 cursor-pointer " onClick={() => handleButtonClick('home')}>
                                                <div className="self-stretch pl-0.5 pr-1 my-auto text-sm text-gray-900 whitespace-nowrap">
                                                    Dashboard
                                                </div>

                                            </div>
                                        </div>

                                        <div className="flex items-center px-1  bg-white h-12 ">
                                            <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                                        </div>

                                        <div className="flex  justify-center items-center bg-white h-12 ">
                                            <div className="flex items-center self-stretch my-auto h-12 w-[110px] cursor-pointer">
                                                <div className="flex items-center self-stretch  pr-1.5 pl-1.5 my-auto h-12 w-[110px]" onClick={() => handleButtonClick('strategies')}>
                                                    <div className="flex flex-col self-stretch my-auto w-[29px]">
                                                        <img
                                                            loading="lazy"
                                                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a75bbb51a7f4ec05f0cbed6f125de0a6c67654f371ca5bd8ae76ed7769bc2e31?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                                                            className="object-contain aspect-square w-[29px]"
                                                        />
                                                    </div>
                                                    <div className="self-stretch pl-0.5 my-auto text-sm text-gray-900 whitespace-nowrap">
                                                        Strategies
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center px-1  bg-white h-12 ">
                                            <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                                        </div>
                                        <div className="flex  justify-center items-center bg-white h-12 ">
                                            <div className="flex items-center self-stretch my-auto h-12 cursor-pointer">
                                                <div className="flex items-center self-stretch  my-auto h-12" onClick={() => handleButtonClick('learning_point')}>
                                                    <div className="flex flex-col self-stretch my-auto">
                                                        <FiBook className="text-xm text-gray-900" />  {/* Add the icon with styling */}
                                                    </div>
                                                    <div className="self-stretch pl-0.5 my-auto text-sm text-gray-900 whitespace-nowrap">
                                                        Learning Hub
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center px-1  bg-white h-12 ">
                                            <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                                        </div>
                                        <div className="flex  gap-0 justify-center items-center bg-white h-12 ">
                                            <div className="flex items-center self-stretch  pr-1.5 pl-1.5 my-auto h-12 cursor-pointer" onClick={() => handleButtonClick('disclaimers')}>
                                                <div className="flex flex-col self-stretch my-auto w-[24px]">
                                                    <img
                                                        loading="lazy"
                                                        src={Disclaimers}
                                                        className="object-cover aspect-square w-[24px] opacity-70"
                                                    />
                                                </div>
                                                <div className="self-stretch pl-0.5 my-auto text-sm text-gray-900 whitespace-nowrap w-[auto]">
                                                    Disclaimers
                                                </div>
                                            </div>

                                        </div>
                                        <div className="flex items-center px-1  bg-white h-12 ">
                                            <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                                        </div>
                                        <div className="flex  gap-0 justify-center items-center bg-white h-12 ">
                                            <div className="flex items-center  pr-1.5 pl-1.5 my-auto h-12 cursor-pointer" onClick={() => handleButtonClick('add_api')}>
                                                <div className="flex flex-col  my-auto w-5">
                                                    <img
                                                        loading="lazy"
                                                        src={APIMANAGEMENT}
                                                        className="object-contain aspect-square w-[24px]"
                                                    />
                                                </div>
                                                <div className="self-stretch pl-0.5 my-auto text-sm text-gray-900 whitespace-nowrap">
                                                    API Management
                                                </div>
                                            </div>

                                        </div>
                                        <div className="flex items-center px-1  bg-white h-12 ">
                                            <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                                        </div>
                                        <div className="flex  gap-0 justify-center items-center bg-white h-12 ">
                                            <div className="flex items-center self-stretch  pr-1.5 pl-1.5 my-auto h-12 cursor-pointer" onClick={() => handleButtonClick('Billings')}>
                                                <div className="flex flex-col self-stretch my-auto w-[24px]">
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/aa4bb62fa238f22bd7a24a9b6ea82a9414df93afa497d53b447e913e00de06b9?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                                                        className="object-contain aspect-square w-[24px]"
                                                    />
                                                </div>
                                                <div className="self-stretch pl-0.5 my-auto text-sm text-gray-900 whitespace-nowrap w-[auto]">
                                                    Billings
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="flex items-start justify-end w-full h-12 max-md:max-w-full">


                                        <div className="flex items-center self-stretch p-1.5 my-auto h-12">
                                            <div className="flex flex-col self-stretch my-auto cursor-pointer" onClick={handleReload}>
                                                <img
                                                    loading="lazy"
                                                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                                                    className="object-contain aspect-square w-6"
                                                />
                                            </div>

                                        </div>
                                        <div className="flex  justify-center items-center self-stretch my-auto bg-white h-12 px-1.5">
                                            <div className="flex items-center self-stretch pr-1  my-auto h-12">
                                                <div className="flex flex-col self-stretch my-auto  cursor-pointer" onClick={handleFullscreen} >
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0355f561f2b8bb78b777bd7fb78332444b799a54151b66afed1d475c11c28d55?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                                                        className="object-contain w-full aspect-square"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center self-stretch px-1.5 my-auto h-12  cursor-pointer" >

                                            <div className="relative">
                                                <div className="flex items-center self-stretch px-1.5 my-auto h-12 cursor-pointer">
                                                    <img
                                                        onClick={handleSettingButtonClick}
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c8746834b4863a7fb7cf8dabbaaacf77c03d71d145aac925a1371a7a0a68014?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                                                        className="object-contain self-stretch my-auto aspect-square w-[21px]"
                                                    />
                                                </div>

                                                {/* Dropdown menu */}
                                                {showDropdown && (
                                                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg">
                                                        <ul className="py-1">
                                                            <li
                                                                className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-200"
                                                                onClick={() => handleOptionClick('add_api')}
                                                            >
                                                                API Management
                                                            </li>
                                                            <li
                                                                className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-200"
                                                                onClick={() => handleOptionClick('Billings')}
                                                            >
                                                                Billings
                                                            </li>
                                                            <li
                                                                className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-200"
                                                                onClick={() => handleOptionClick('Contacts')}
                                                            >
                                                                Contact Us
                                                            </li>
                                                            <li
                                                                className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-200"
                                                                onClick={() => handleOptionClick('Help')}
                                                            >
                                                                Help
                                                            </li>
                                                            <li
                                                                className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-200 cursor-pointer"
                                                                onClick={handleLogout}
                                                            >
                                                                Log Out
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {isOnline ? (
                                            <img
                                                width="38"
                                                height="38"
                                                className="object-contain shrink-0 self-stretch my-auto px-1.5"
                                                src={Wifi}
                                                alt="wi-fi-connected"
                                            />
                                        ) : (
                                            <img
                                                width="38"
                                                height="38"
                                                className="object-contain shrink-0 self-stretch my-auto px-1.5"
                                                src={WifiDisconected}
                                                alt="wi-fi-disconnected"
                                            />
                                        )}
                                        {/* <div className="flex items-center pr-1.5 text-sm leading-none text-white bg-white">
                                            <div className="flex justify-center items-center self-stretch my-auto h-12 ">
                                                <div className="self-stretch flex justify-center items-center px-3 my-auto bg-orange-500 rounded-2xl min-h-[29px] w-[72px]">
                                                    Live
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {showPopup && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
                // onClick={closePopup}
                >
                    <div
                        className="bg-white rounded-lg p-6 w-[450px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-lg font-bold mb-4">Update Your Information</h2>
                        {result && <p className="text-red-500" >{result}</p>}
                        <form onSubmit={handleFormSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">First Name</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleInputChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                    disabled={isSubmitting} // Disable input when submitting
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleInputChange}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                    disabled={isSubmitting} // Disable input when submitting
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Mobile No</label>
                                <input
                                    type="text"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleInputChange}
                                    placeholder='Mobile No. With Country Code'
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                    disabled={isSubmitting} // Disable input when submitting
                                />
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
                                    onClick={closePopup}
                                >
                                    Cancel
                                </button>



                                <button
                                    type="submit"
                                    className="w-full bg-blue-500 text-white px-4 py-2 rounded-md flex items-center justify-center"
                                    disabled={isSubmitting} // Disable button when submitting
                                >
                                    <div className="flex items-center justify-center">
                                        {isSubmitting ? (
                                            <svg
                                                className="w-5 h-5 mr-3 text-white animate-spin"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                ></path>
                                            </svg>
                                        ) : null}
                                        {isSubmitting ? "Submiting..." : "Submit"}
                                    </div>

                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

        </nav>
    );
};

export default NavBar;
