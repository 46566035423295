import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SignIN from './Page/auth/SignIn';
import ForgetPassword from './Page/auth/ForgetPassword';
import SignUp from './Page/auth/Signup';
import Dashboard from './Page/Dashboard';
import AdminDashboard from './Page/Admin/Dashboard';
import { logout } from './actions/authActions';
import './App.css';
import apiClient from './actions/axiosInterceptor';
import axios from 'axios';
import baseURL from './url';
import LoginImage from './assets/image/loginpage.png'

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [password, setPassword] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const passwordInputRef = useRef(null);

  const isAuthenticated = useSelector((state) => !!state.auth.accessToken);
  const user = useSelector((state) => state.auth.user);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const refreshToken = useSelector((state) => state.auth.refreshToken);

  const handleLogout = async () => {
    dispatch(logout(refreshToken, accessToken));
  };

  useEffect(() => {
    const hasAccess = localStorage.getItem('maintenanceAccess');

    if (hasAccess === 'true') {
      setIsMaintenance(false); // Skip maintenance screen if access is stored
      setLoading(false); // Ready to render
    } else {
      const fetchMaintenanceStatus = async () => {
        try {
          const response = await apiClient.get('/adminestration/api/maintenance-status/');
          const status = response.data.status === 'true' || response.data.status === true;
          setIsMaintenance(status);
        } catch (error) {
          console.error('Error fetching maintenance status:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchMaintenanceStatus();
    }
  }, []);

  useEffect(() => {
    if (isMaintenance && passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, [isMaintenance]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSubmit(true);

    try {
      const response = await axios.post(`${baseURL}/adminestration/api/verify-password/`, { password });

      if (response.data.success) {
        localStorage.setItem('maintenanceAccess', 'true'); // Store access as true
        setIsMaintenance(false); // Update maintenance status
        setPassword(''); // Clear password input
      } else {
        alert('Incorrect password. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying password:', error);
      alert('An error occurred while verifying the password.');
    } finally {
      setLoadingSubmit(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <svg
          className="w-10 h-10 mr-3 text-gray-500 animate-spin"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <>
      {isMaintenance ? (
        <div className="flex items-center justify-center min-h-screen bg-orange-500 text-white text-center">
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <p className='text-2xl w-[500px] text-justify p-2'>Under Maintenance: Your automated orders will continue running as usual. We're just performing a routine maintenance check.</p>

            <input
              ref={passwordInputRef}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='p-2 rounded outline-none text-black w-[300px]'
              placeholder='Developer Password'
              required
            />
            <button
              type="submit"
              className="mt-2 w-[300px] py-2 bg-[#122A31] text-white font-medium rounded-md hover:bg-[#0A1A23] transition"
              disabled={loadingSubmit}
            >
              {loadingSubmit ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </div>
      ) : (
        <Routes>
          {isAuthenticated && user?.role === 'user' ? (
            <>
              <Route path="/" element={<Dashboard user={user} accessToken={accessToken} handleLogout={handleLogout} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : user?.role === 'admin' ? (
            <>
              <Route path="/" element={<AdminDashboard user={user} accessToken={accessToken} handleLogout={handleLogout} />} />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/sign-in" element={<SignIN />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="*" element={<Navigate to="/sign-in" />} />
            </>
          )}
        </Routes>
      )}
    </>
  );
}

export default App;
