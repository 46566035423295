import React, { useState, useEffect, useRef } from 'react';
import '../assets/css/Dashboard.css';
import apiClient from './../actions/axiosInterceptor';
import baseURL from './../url';

const Contacts = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {

    // Function to dynamically load the Calendly script
    useEffect(() => {
        // Add the Calendly widget script dynamically
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);




    return (
        <div className={`h-[100%] ${selectedFilterfullHeightButton === true ? '' : 'pb-[0]'} transform transition-all duration-500 ease-out translate-y-full opacity-0 animate-slide-up`} >
            <div className='h-[100%] bg-white rounded rounded-bl-[14px] rounded-br-[14px] p-[10px]'>
                <div
                    className="calendly-inline-widget"
                    data-url="https://calendly.com/hedgeplus/meet-with-team-hedge"
                    style={{ minWidth: '320px', height: '700px' }}
                ></div>
            </div>
        </div>
    );
};

export default Contacts;
