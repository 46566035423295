const environment = process.env.REACT_APP_ENV;

let baseURL;

switch (environment) {
    case 'production':
        baseURL = process.env.REACT_APP_PROD_API_BASE_URL;
        break;
    case 'development':
    default:
        baseURL = process.env.REACT_APP_DEV_API_BASE_URL;
        break;
}

console.log(`API Base URL: ${baseURL}`);
console.log(`Environment: ${environment}`);

export default baseURL;