import React, { useEffect, useRef, memo, useState } from 'react';
import '../assets/css/Dashboard.css';
import apiClient from '../actions/axiosInterceptor';

const Home = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    const container = useRef();
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch user data if access token is available
    const fetchUserData = async () => {
        try {
            const response = await apiClient.get('/auth/api/me/');
            console.log(response.data)
            setUserData(response.data); // Assuming response contains user data

        } catch (error) {
            console.error('Error fetching user data:', error);
            if (error.response && error.response.status === 401) {
                handleLogout(); // Log out the user if unauthorized
            }
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [accessToken]); // Add `handleLogout` as a dependency if it's coming from props or state

    useEffect(() => {

        const socket = new WebSocket('wss://beta.hedgemarc.ca/ws/trades/');

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);

                fetchUserData();

            } catch (error) {
                console.error('Error parsing WebSocket message:', error);

            }
        };

        fetchUserData();

    }, [accessToken]);


    useEffect(() => {
        const widgetContainer = document.getElementById('tradingview-widget-chart');

        if (widgetContainer && !widgetContainer.querySelector('script')) {
            widgetContainer.innerHTML = '';  // Clear the widget container

            // Create a new script element for the TradingView widget
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;

            // Define the widget's configuration
            script.innerHTML = `
          {
            "autosize": true,
            "symbol": "BINANCE:ETHUSDT",
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "light",
            "style": "1",
            "locale": "en",
            "withdateranges": true,
            "hide_side_toolbar": false,
            "allow_symbol_change": true,
            "details": true,
            "hotlist": true,
            "calendar": false,
            "support_host": ""
          }`;

            widgetContainer.appendChild(script);


        }
    }, []); // Only run on initial mount and cleanup on unmount

    return (
        <div className={`h-[100%] transform transition-all duration-500 ease-out translate-y-full opacity-0 animate-slide-up`}>
            <div className='h-[20%] bg-white rounded-[10px]  mb-1 p-4 flex items-center justify-evenly w-full' >

                <div className='flex p-2 items-center'>
                    <div className="bg-orange-500 border border-orange-500 p-2 rounded-full w-10 h-10 flex items-center justify-center text-white cursor-pointer m-2">
                        {user?.first_name.charAt(0).toUpperCase()}
                    </div>
                    <div className='m-2'>
                        <h2 className='text-xl font-bold' >Hello, {user.first_name}</h2>
                        <p className='text-l typing-effect' >Welcome back, let's continue</p>
                    </div>
                </div>
                <div className='p-2'>
                    <p className='text-xs' >Local Account Value</p>
                    <h2 className='text-xl font-bold text-grey-700' >${userData?.total_risk_value}</h2>
                </div>
                <div className='p-2'>
                    <p className='text-xs' >Active Long/Short</p>
                    <h2 className='text-xl font-bold' >{userData?.long_orders_count}/{userData?.short_orders_count}</h2>
                </div>
                <div className='p-2'>
                    <p className='text-xs' >Open Order(s)</p>
                    <h2 className='text-xl font-bold' >${userData?.total_open_risk_value}</h2>
                </div>
                <div className='p-2'>
                    <p className='text-xs' >Available Risk Capital</p>
                    <h2 className='text-xl font-bold' >${userData?.available_risk_value}</h2>
                </div>
            </div>
            <div className='h-[80%] bg-white rounded rounded-tl-[14px] rounded-tr-[14px] mt-2' >
                <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
                    <div className="tradingview-widget-container__widget" id="tradingview-widget-chart" style={{ height: "100%", width: "100%" }}></div>
                </div>
            </div>
        </div>
    );
};

export default memo(Home);
