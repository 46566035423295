import React, { useState, useEffect } from 'react';
import Navbar from '../components/NavBar';
import BottomMenu from '../components/BottomMenu';
import Home from '../components/Home';
import OpenOrders from '../components/OpenOrders';
import StrategyData from '../components/StrategyData';
import CreateOrder from '../components/CreateOrder';
import AddApi from '../components/AddApi';
import Contacts from '../components/Contacts';
import MyOrders from '../components/MyOrders';
import Disclaimer from '../components/Disclaimer';
import Strategies from '../components/Strategies';
import LearningPoint from '../components/LearningPoint';
import PaymentPage from '../components/PaymentPage';
import RightComponent from '../components/RightComponent';
import LeftComponent from '../components/LeftComponent';
import MarketCharts from '../components/MarketCharts';
import ForexCharts from '../components/ForexCharts';
import StocksCharts from '../components/StocksCharts';
import CryptoCharts from '../components/CryptoCharts';
import { FaComments } from 'react-icons/fa';
import Sidebar from '../components/Sidebar';
import AiAssistent from '../components/AiAssistent';

const Dashboard = ({ user, accessToken, handleLogout }) => {
    const [selectedButton, setSelectedButton] = useState(null);

    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const openSidebar = () => setSidebarOpen(true);
    const closeSidebar = () => setSidebarOpen(false);

    useEffect(() => {
        const savedButton = localStorage.getItem('selectedButton');
        if (savedButton) {
            setSelectedButton(savedButton);
        }
    }, []);

    const handleActiveButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        localStorage.setItem('selectedButton', buttonName); // Save to localStorage
    };

    const [selectedFilterfullHeightButton, setSelectedFilterfullHeightButton] = useState(false);

    useEffect(() => {
        const savedButtons = localStorage.getItem('selectedFilterfullHeightButtons');
        if (savedButtons) {
            setSelectedFilterfullHeightButton(selectedFilterfullHeightButton); // Parse the JSON string into an array
        }
    }, [selectedFilterfullHeightButton]);

    const handleActiveFilterfullHeightButtonClick = (buttonName) => {
        setSelectedFilterfullHeightButton(!selectedFilterfullHeightButton);
        localStorage.setItem('selectedFilterfullHeightButtons', !selectedFilterfullHeightButton); // Save to localStorage
    };


    const renderComponent = () => {
        switch (selectedButton) {
            case 'home':
                return <Home user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
            case 'openOrders':
                return <OpenOrders user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
            case 'strategyData':
                return <StrategyData user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
            case 'MyOrders':
                return <MyOrders user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
            case 'Billings':
                return <PaymentPage user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
            case 'Contacts':
                return <Contacts user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
            case 'create_order':
                return <CreateOrder user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} handleActiveButtonClick={handleActiveButtonClick} />
            case 'add_api':
                return <AddApi user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />
            case 'disclaimers':
                return <Disclaimer user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />
            case 'strategies':
                return <Strategies user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />
            case 'learning_point':
                return <LearningPoint user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />
            case 'markets_charts':
                return <MarketCharts />
            case 'forex_charts':
                return <ForexCharts />
            case 'crypto_charts':
                return <CryptoCharts />
            case 'stocks_charts':
                return <StocksCharts />
            case 'aiAssistant':
                return <AiAssistent user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />
            default:
                return <Home user={user} accessToken={accessToken} handleLogout={handleLogout} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />;
        }
    };



    return (
        <div style={{ overflow: "hidden" }} className='h-screen overflow-hidden' >

            <Navbar user={user} accessToken={accessToken} handleLogout={handleLogout} handleActiveButtonClick={handleActiveButtonClick} openSidebar={openSidebar} />
            <section className=' h-[100%] flex w-full' >
                <div className='w-14 h-[100%] bg-white  mt-1.5' style={{ borderTopRightRadius: "4px" }} >
                    <LeftComponent user={user} accessToken={accessToken} handleLogout={handleLogout} handleActiveButtonClick={handleActiveButtonClick} />
                </div>
                <div className='w-[73%] h-[100%] mr-2' >
                    <div className={`w-full h-[calc(100%-7rem)] m-1.5`}>
                        {renderComponent()}
                    </div>
                    <div className='w-full bg-white h-12 m-1.5 rounded'>
                        <BottomMenu user={user} accessToken={accessToken} handleLogout={handleLogout} handleActiveButtonClick={handleActiveButtonClick} selectedButton={selectedButton} handleActiveFilterfullHeightButtonClick={handleActiveFilterfullHeightButtonClick} selectedFilterfullHeightButton={selectedFilterfullHeightButton} />
                    </div>
                </div>
                <div className='w-[25%]  h-[100%] bg-white ml-1 mt-1.5 rounded' >
                    <RightComponent user={user} accessToken={accessToken} handleLogout={handleLogout} />
                </div>

                <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} user={user} />
            </section>

        </div>
    );
};

export default Dashboard;
