import React, { useState, useEffect } from 'react';
import '../assets/css/Dashboard.css';
import apiClient from './../actions/axiosInterceptor';
import baseURL from './../url';


const LearningPoint = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    const [loading, setLoading] = useState(false);
    const [LearningPoint, setLearningPoint] = useState([]); // State to store trades

    const fetchLearningPoint = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get(`/analytics/api/learning-point/`);
            console.log(response)
            const data = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setLearningPoint(data);

            setLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLearningPoint();
    }, []);

    return (
        <div className={`h-full ${selectedFilterfullHeightButton ? '' : ''} transition-all duration-500 ease-out transform translate-y-full opacity-0 animate-slide-down`}>
            <div className="h-full w-full bg-white rounded-bl-xl rounded-br-xl overflow-scroll">

                <div className="m-4 flex w-full flex-wrap items-stretch">
                    {LearningPoint.map((s, index) => (
                        <div className="flex flex-col rounded-[16px] w-[47%] m-2">
                            <div className="flex flex-col bg-[#f0f3fa] p-4 rounded-[16px] shadow-lg">
                                <div className="flex items-center mb-4">
                                    <div className="bg-white rounded-full flex items-center justify-center">
                                        {/* SVG Icon */}
                                        <svg width="48" height="48" viewBox="0 0 299 119" fill="none" xmlns="http://www.w3.org/2000/svg" className='p-1'>
                                            <g filter="url(#filter0_b_192_260)">
                                                <rect x="76" width="16" height="94" fill="black" />
                                            </g>
                                            <g filter="url(#filter1_b_192_260)">
                                                <rect x="128" width="16" height="94" fill="black" />
                                            </g>
                                            <g filter="url(#filter2_b_192_260)">
                                                <rect x="184" width="16" height="94" fill="black" />
                                            </g>
                                            <g filter="url(#filter3_b_192_260)">
                                                <rect x="240" width="16" height="94" fill="black" />
                                            </g>
                                            <g filter="url(#filter4_b_192_260)">
                                                <path d="M102 45C102 40.5817 105.582 37 110 37C114.418 37 118 40.5817 118 45V94H102V45Z" fill="black" />
                                            </g>
                                            <ellipse cx="108" cy="11.5" rx="10" ry="9.5" fill="black" />
                                            <g filter="url(#filter5_b_192_260)">
                                                <path d="M156 70C156 65.5817 159.582 62 164 62C168.418 62 172 65.5817 172 70V94H156V70Z" fill="black" />
                                            </g>
                                            <ellipse cx="164" cy="41.5" rx="10" ry="9.5" fill="black" />
                                            <g filter="url(#filter6_b_192_260)">
                                                <path d="M212 70C212 65.5817 215.582 62 220 62C224.418 62 228 65.5817 228 70V94H212V70Z" fill="black" />
                                            </g>
                                            <ellipse cx="220" cy="41.5" rx="10" ry="9.5" fill="black" />
                                            <ellipse cx="220" cy="13.5" rx="10" ry="9.5" fill="black" />
                                            <path d="M5.76367 45.6H54.2637V58.4H5.76367V45.6ZM23.5637 27.8H36.4637V76.3H23.5637V27.8Z" fill="black" />
                                            <defs>
                                                <filter id="filter0_b_192_260" x="72" y="-4" width="24" height="102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_192_260" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_192_260" result="shape" />
                                                </filter>
                                                <filter id="filter1_b_192_260" x="124" y="-4" width="24" height="102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_192_260" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_192_260" result="shape" />
                                                </filter>
                                                <filter id="filter2_b_192_260" x="180" y="-4" width="24" height="102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_192_260" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_192_260" result="shape" />
                                                </filter>
                                                <filter id="filter3_b_192_260" x="236" y="-4" width="24" height="102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_192_260" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_192_260" result="shape" />
                                                </filter>
                                                <filter id="filter4_b_192_260" x="98" y="33" width="24" height="65" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_192_260" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_192_260" result="shape" />
                                                </filter>
                                                <filter id="filter5_b_192_260" x="152" y="58" width="24" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_192_260" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_192_260" result="shape" />
                                                </filter>
                                                <filter id="filter6_b_192_260" x="208" y="58" width="24" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_192_260" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_192_260" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>

                                    </div>
                                    <div className="ml-4">
                                        <h2 className="text-sm font-bold text-black">{s.title}</h2>
                                        <p className="text-xs text-black">{s.text}</p>
                                    </div>
                                </div>
                                <div className="flex items-center justify-center bg-black text-white rounded mb-2 cursor-pointer">
                                    <iframe className='rounded'
                                        src={s.link}
                                        width="560" height="315"
                                        style={{ border: 'none' }}
                                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen
                                    ></iframe>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>


            </div>
        </div>
    );
};

export default LearningPoint;
