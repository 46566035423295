import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import apiClient from '../actions/axiosInterceptor';
import baseURL from '../url';
import DateTimeWithTooltip from './DateTimeWithTooltip';
import '../assets/css/Dashboard.css';


const RightComponent = ({ user, accessToken, handleLogout }) => {
    // Define a state to track the selected tab
    const [activeTab, setActiveTab] = useState('markets'); // Default tab is "Markets"
    const [trades, setTrades] = useState([]); // State to store trades
    const [tradesData, setTradesData] = useState([]); // State to store trades

    const [activeTrades, setActiveTrades] = useState([]); // State to store trades
    const [loading, setLoading] = useState(true); // State for loader

    const [total, setTotal] = useState(0); // State for loader

    const [selectedOption, setSelectedOption] = useState('crypto');
    const [markets, setMarkets] = useState([]);
    const [orders, setOrders] = useState([]);
    const [market, setMarket] = useState('markets');
    const [timeFrame, setTimeFrame] = useState('All');
    const [timeFrames, setTimeFrames] = useState([]);

    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(1);


    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        console.log('Selected Option:', event.target.value);
        // Save the selected option wherever you need (e.g., local storage or a database)
    };


    // Fetch initial trades data from the API
    const fetchActiveOrders = async () => {
        try {
            const response = await apiClient.get(`${baseURL}/analytics/api/open-order/?status=Active`);
            const sortedTrades = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            const uniqueOrders = [...new Set(sortedTrades.map((order) => order.symbol))];
            setTotal(sortedTrades.length)
            setOrders(uniqueOrders);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setLoading(false);
        }
    };

    // Fetch initial trades data from the API
    const fetchActiveOrdersData = async () => {
        try {
            const response = await apiClient.get(`${baseURL}/analytics/api/open-order/?status=Active&symbol=${market}&timeFrame=${timeFrame}`);
            const sortedTrades = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setActiveTrades(sortedTrades);

            setLoading(false);
            const uniqueOrders = [...new Set(sortedTrades.map((order) => order.symbol))];
            setOrders(uniqueOrders);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setLoading(false);
        }
    };

    // Fetch initial trades data from the API
    const fetchTrades = async () => {
        try {
            const response = await apiClient.get(`${baseURL}/analytics/trade/alert/?market=${market}&timeFrame=${timeFrame}&page=${page}`);
            console.log(response)
            const totalData = response.data.count;
            const totalResultData = Object.keys(response.data.results.data).length;
            console.log(totalData / totalResultData);
            const maxPageNo = Math.floor(totalData / totalResultData) + 1;
            setMaxPage(maxPageNo);
            const sortedTrades = response.data.results.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            console.log(sortedTrades, page);
            if (page === 1) {
                setTrades(sortedTrades); // Replace trades on the first page
            } else {
                setTrades((prevTrades) => [...prevTrades, ...sortedTrades]);
            }

            setLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setLoading(false);
        }
    };

    // Fetch initial trades data from the API
    const fetchTradesData = async () => {
        try {
            const response = await apiClient.get(`${baseURL}/analytics/trade/alert/?unique_data=1`);
            const sortedTrades = response.data.unique_combinations.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setTradesData(sortedTrades);
            const uniqueMarkets = [...new Set(sortedTrades.map((trade) => trade.market))];
            setMarkets(uniqueMarkets);
            const uniqueTimeFrames = [...new Set(sortedTrades.map((trade) => trade.time_frame))];
            setTimeFrames(uniqueTimeFrames);

            // if (uniqueMarkets.length > 0) {
            //     setMarket(uniqueMarkets[0]);
            // }

            setLoading(false);
        } catch (err) {
            console.error('Error fetching trades:', err);
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchTradesData();
        fetchActiveOrders();
        fetchTrades();
    }, [accessToken]);

    useEffect(() => {
        fetchActiveOrdersData();
        fetchTrades();
    }, [accessToken, market, timeFrame, page]);


    useEffect(() => {

        const socket = new WebSocket('wss://beta.hedgemarc.ca/ws/trades/');

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                console.log('Received data:', data);

                fetchTrades();
                fetchTradesData();
                fetchActiveOrders();
                fetchActiveOrdersData();

            } catch (error) {
                console.error('Error parsing WebSocket message:', error);

            }
        };

        fetchTrades();
        fetchTradesData();
        fetchActiveOrders();
        fetchActiveOrdersData();

    }, [accessToken]);

    const handleMarketChange = (selectedMarket) => {
        setPage(1);
        setMarket(selectedMarket);
    };

    const handleTimeFrameClick = (timeFrame) => {
        setTimeFrame(timeFrame); // Update the selected time frame
        setPage(1);
    };



    // Function to handle tab switching
    const handleTabClick = (tab) => {
        setActiveTab(tab);
        handleMarketChange(tab);
    };

    useEffect(() => {
        // Use setTimeout to ensure the DOM is fully loaded before the script runs
        const widgetContainer = document.getElementById('tradingview-widget');


        if (widgetContainer && !widgetContainer.querySelector('script')) {
            widgetContainer.innerHTML = '';  // Clear the widget container
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
            script.async = true;

            // Widget configuration in JSON format
            script.innerHTML = JSON.stringify({
                feedMode: "market",
                isTransparent: true,
                displayMode: "adaptive",
                width: "100%",
                height: "100%",
                colorTheme: "light",
                locale: "en",
                market: selectedOption
            });

            widgetContainer.appendChild(script);
        }
    }, [selectedOption]);


    const scrollRef = useRef(null);

    // Scroll event listener to load more data when reaching bottom
    const handleScroll = () => {
        const scrollDiv = scrollRef.current;
        if (scrollDiv.scrollTop + scrollDiv.clientHeight >= scrollDiv.scrollHeight && !loading) {
            // Reached the bottom of the scrollable div, load more data
            setPage((prevPage) => {
                if (prevPage < maxPage) {
                    return prevPage + 1;
                }
                return prevPage;
            }
            );
        }
    };



    return (
        <div className='w-full h-full bg-white h-12 rounded'>

            <div className="flex flex-col h-full w-full">
                <div className="flex flex-col h-full bg-white rounded border-r border-l border-zinc-200 min-h-[100%]">

                    <div className="flex flex-col h-full bg-white rounded w-full">
                        <div className="flex items-center p-2 w-full text-center h-[49px]">
                            <div className="flex flex-col self-stretch my-auto bg-white w-full">
                                <div className="flex gap-1 items-start p-1 w-full rounded-lg bg-slate-100 w-full">
                                    <div className={`flex w-[50%] justify-center items-center p-1 text-sm font-bold leading-nones whitespace-nowrap  ${activeTab === 'markets' ? 'bg-white' : ''}  rounded-md  min-h-[29px] `}>
                                        <div className={`overflow-hidden self-stretch my-auto cursor-pointer }`}
                                            onClick={() => handleTabClick('markets')}>
                                            Markets
                                        </div>
                                    </div>
                                    <div className={`flex w-[50%] justify-center items-center p-1 text-sm font-bold leading-none whitespace-nowrap  ${activeTab === 'orders' ? 'bg-white ' : ''} rounded-md  min-h-[29px] `}>
                                        <div className={`overflow-hidden self-stretch my-auto text-sm leading-none cursor-pointer`}
                                            onClick={() => handleTabClick('orders')}>
                                            Open Orders
                                        </div>
                                        <div className="self-stretch py-px  my-auto text-xs leading-tight text-white whitespace-nowrap rounded-lg border border-white border-solid bg-orange-500 bg-opacity-80 min-h-[15px]">
                                            {total}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex overflow-hidden h-[90%] flex-col w-full">
                            <div className="flex flex-col items-center  h-[100%]">
                                <div className="flex flex-col items-center w-full  h-[100%]">
                                    <div className="flex flex-col justify-center w-full  h-[60%]">
                                        <div className="flex gap-2.5 items-center py-2 pl-4 w-full bg-white border-b border-zinc-200 h-[10%]">
                                            <div className="text-sm text-gray-900 ">
                                                <select className='cursor-pointer outline-none' value={market}
                                                    onChange={(e) => handleMarketChange(e.target.value)} >
                                                    {activeTab === 'markets' ? (
                                                        <>
                                                            <option selected value="markets">Market</option>
                                                            {markets.map((market, index) => (
                                                                <option key={index} value={market}>{market}</option>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <option selected value="orders">Symbols</option>
                                                            {orders.map((o, index) => (
                                                                <option key={index} value={o}>{o}</option>
                                                            ))}
                                                        </>
                                                    )}


                                                </select>
                                            </div>

                                            <div className="flex gap-2 overflow-scroll w-full self-stretch my-auto text-xs font-medium text-center whitespace-nowrap text-neutral-800">
                                                <div
                                                    className={`px-2 py-0.5 rounded-md cursor-pointer ${timeFrame === 'All'
                                                        ? 'bg-black text-white'  // Apply black background and white text for selected
                                                        : 'bg-white text-neutral-800'  // Default white background and neutral text
                                                        }`}
                                                    onClick={() => handleTimeFrameClick("All")}  // Update time frame on click
                                                >
                                                    All
                                                </div>
                                                {timeFrames.map((t, index) => (
                                                    <>

                                                        <div
                                                            key={index}
                                                            className={`px-2 py-0.5 rounded-md cursor-pointer ${timeFrame === t
                                                                ? 'bg-black text-white'  // Apply black background and white text for selected
                                                                : 'bg-white text-neutral-800'  // Default white background and neutral text
                                                                }`}
                                                            onClick={() => handleTimeFrameClick(t)}  // Update time frame on click
                                                        >
                                                            {t}
                                                        </div>
                                                    </>

                                                ))}
                                            </div>
                                            {/* <div className="flex flex-col self-stretch my-auto ">
                                                <div className="flex items-start pt-px w-full h-9 ]">
                                                    <div className="flex items-center pl-1.5 w-10 min-h-[35px]">
                                                        <div className="flex justify-center items-center self-stretch py-1 pl-1 my-auto rounded min-h-[35px] w-[35px]">
                                                            <div className="flex self-stretch my-auto min-h-[29px] w-[29px]" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="flex flex-col w-full h-[90%]">
                                            {activeTab === 'markets' ? (

                                                <div className="flex flex-col w-full h-[100%]">
                                                    <div className=" w-full h-[90%] overflow-scroll justify-between  items-center  p-2 text-sm leading-none text-gray-900 whitespace-nowrap" ref={scrollRef}
                                                        onScroll={handleScroll}>

                                                        {trades.map((row, index) => (

                                                            <div key={index} className="flex w-full justify-between items-center p-1 text-sm leading-none text-gray-900 whitespace-nowrap">
                                                                <div className='flex items-center' style={{ fontSize: '11px', width: '20%', textAlign: 'left' }}>
                                                                    <div style={{
                                                                        width: "10px",
                                                                        height: "11px",
                                                                        background: "#131722",
                                                                        transform: "matrix(1, 0, -0.39, 0.92, 0, 0)"
                                                                    }}></div>&nbsp;{row.symbol}
                                                                </div>

                                                                <div className='text-sm' title={row.strategy} style={{ fontSize: '11px', width: '25%', textAlign: 'left' }}>
                                                                    {row.strategy.slice(0, 20)}
                                                                </div>

                                                                <div className='text-sm' style={{ fontSize: '11px', width: '10%', textAlign: 'left' }}>
                                                                    {row.entry_type}
                                                                </div>

                                                                <div className='text-sm' style={{ fontSize: '9px', width: '15%', textAlign: 'right' }}>
                                                                    <DateTimeWithTooltip dateString={row.created_at} /> {/* Date with tooltip */}
                                                                </div>

                                                            </div>

                                                        ))}
                                                    </div>
                                                    <div className="flex  flex-col pt-1 mt-0 w-full min-h-[52px]">
                                                        <div className="flex overflow-hidden justify-center items-start pr-4 pl-4 w-full min-h-[35px]">
                                                            <div className="flex overflow-hidden relative flex-col items-start w-full">
                                                                <div className="flex overflow-hidden z-0 flex-col justify-center items-start self-stretch w-full text-sm leading-none rounded-lg border border-gray-400 border-solid  text-zinc-400">
                                                                    <input className="w-full min-h-[35px] p-1" style={{ border: "none", outline: "none" }} placeholder='Search' />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="flex flex-col w-full h-[100%]">
                                                    <div className=" w-full h-[90%] overflow-scroll justify-between  items-center  p-2 text-sm leading-none text-gray-900 whitespace-nowrap">

                                                        {activeTrades.map((row, index) => (

                                                            <div key={index} className="flex w-full justify-between  items-center  p-1 text-sm leading-none text-gray-900 whitespace-nowrap">
                                                                <div className='text-sm flex items-center' style={{ fontSize: '12px' }}>
                                                                    <div style={{
                                                                        width: "11px",
                                                                        height: "12px",
                                                                        left: "23.7px",
                                                                        top: "10px",
                                                                        background: "#E0745F",
                                                                        transform: "matrix(1, 0, -0.39, 0.92, 0, 0)"
                                                                    }} ></div>&nbsp;{row.symbol}</div>
                                                                <div className='text-sm' style={{ fontSize: '12px' }}  >{row.strategy}</div>
                                                                <div className='text-sm' style={{ fontSize: '12px' }}  >{row.long_or_short}</div>
                                                                <div className='text-sm' style={{ fontSize: '12px' }}  ><DateTimeWithTooltip dateString={row.created_at} /></div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="flex z-10 flex-col pt-1 mt-0 w-full min-h-[52px]">
                                                        <div className="flex overflow-hidden justify-center items-start pr-4 pl-4 w-full min-h-[35px]">
                                                            <div className="flex overflow-hidden relative flex-col items-start w-full">
                                                                <div className="flex overflow-hidden z-0 flex-col justify-center items-start self-stretch w-full text-sm leading-none rounded-lg border border-gray-400 border-solid  text-zinc-400">
                                                                    <input className="w-full min-h-[35px] p-1" style={{ border: "none", outline: "none" }} placeholder='Search' />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center w-full  h-[40%]">
                                        <div className="flex w-full border-t-4 border-slate-100 min-h-[4px]" />
                                        <div className="flex gap-10 justify-between items-center px-4 py-2 w-full bg-white border-b border-zinc-200 min-h-[50px]">
                                            <div className="overflow-hidden self-stretch my-auto text-sm font-bold leading-none text-gray-900 whitespace-nowrap">
                                                Talks
                                            </div>
                                            <div className="flex flex-col self-stretch my-auto ">
                                                <div className="flex pt-px pr-0.5 h-9">
                                                    <div className="flex flex-col items-start pl-1.5">
                                                        <div className="flex justify-center items-center p-1 rounded min-h-[35px]">
                                                            <select className='cursor-pointer outline-none' value={selectedOption}
                                                                onChange={handleChange} >
                                                                <option value="crypto" >Cryptocurrencies</option>
                                                                <option value="forex" >Currencies</option>
                                                                <option value="stock" >Stocks</option>
                                                                <option value="index" >Indices</option>
                                                                <option value="futures" >Futures</option>
                                                                <option value="cfd" >Bonds</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full h-[75%]">
                                            <div className="flex overflow-scroll w-full h-[100%]">
                                                <div className="flex flex-col h-[100%] w-full bg-stone-50 ">
                                                    <div className="tradingview-widget-container h-full">
                                                        <div id="tradingview-widget" className="tradingview-widget-container__widget h-full"></div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

};

export default RightComponent;