import React, { useState, useEffect } from 'react';
import '../assets/css/Dashboard.css';
import bulbImg from '../assets/image/bulb.png';
import baseURL from './../url';
import apiClient from './../actions/axiosInterceptor';

const CreateOrder = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton, handleActiveButtonClick }) => {
    // State to manage the selected button
    const [selectedButton, setSelectedButton] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // State to manage form inputs
    const [broker, setBroker] = useState('');
    const [market, setMarket] = useState('');
    const [symbol, setSymbol] = useState('');
    const [selectedApi, setSelectedApi] = useState('');
    const [timeFrame, setTimeFrame] = useState('');
    const [direction, setDirection] = useState('Long');
    const [stopLoss, setStopLoss] = useState('Strategy Stop Loss');
    const [status, setStaus] = useState('Active');
    const [optionStrategies, setOptionStrategies] = useState('Future');
    const [orderType, setOrderType] = useState('Market Order');
    const [riskCapitalValue, setRiskCapitalValue] = useState('');
    const [swap, setSwap] = useState('Normal');

    const [markets, setMarkets] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const [strategies, setStrategies] = useState([]);
    const [allBrokers, setAllBrokers] = useState([]);
    const [symbols, setSymbols] = useState([]);
    const [timeFrames, setTimeFrames] = useState([]);

    const [trades, setTrades] = useState([]);
    const [api, setApi] = useState([]);

    useEffect(() => {

        const fetchSavedApis = async () => {
            try {
                const response = await apiClient.get(`/analytics/api/create-api-integration/`);
                const sortedApi = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                console.log(sortedApi);
                setApi(sortedApi);
            } catch (err) {
                console.error('Error fetching trades:', err);
            }
        };

        fetchSavedApis();
    }, [accessToken]);


    useEffect(() => {
        // Fetch initial trades data from the API
        const fetchAllTrades = async () => {
            try {
                const response = await apiClient.get(`${baseURL}/analytics/trade/alert/?unique_data=1`);
                console.log(response)
                const sortedTrades = response.data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                const uniqueStrategy = [...new Set(sortedTrades.map((trade) => trade.strategy))];
                setStrategies(uniqueStrategy);
                if (uniqueStrategy.length > 0) {
                    setSelectedButton(uniqueStrategy[0]); // Default to first strategy
                }
            } catch (err) {
                console.error('Error fetching trades:', err);
            }
        };

        fetchAllTrades();
    }, [accessToken]);

    useEffect(() => {
        // Fetch initial trades data from the API
        const fetchTrades = async () => {
            try {
                setBrokers([]);
                const response = await apiClient.get(`${baseURL}/analytics/trade/alert/?strategy=${selectedButton}`);
                const sortedTrades = response.data.results.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setTrades(sortedTrades);
                const uniqueMarkets = [...new Set(sortedTrades.map((trade) => trade.market))];
                const uniqueBrokers = [...new Set(sortedTrades.map((trade) => trade.broker))];
                // const uniqueBrokers = sortedTrades.map((trade) => ({
                //     broker: trade.broker,
                //     market: trade.market,
                // }));
                const uniqueSymbols = [...new Set(sortedTrades.map((trade) => trade.symbol))];
                const uniqueTimeFrames = [...new Set(sortedTrades.map((trade) => trade.time_frame))];

                setMarkets(uniqueMarkets);
                setBrokers(uniqueBrokers);

                setSymbols(uniqueSymbols);
                setTimeFrames(uniqueTimeFrames);
            } catch (err) {
                console.error('Error fetching trades:', err);
            }
        };

        fetchTrades();
    }, [accessToken, selectedButton]);

    const handleMarketChange = (selectedMarket) => {
        setMarket(selectedMarket);

        // // Filter brokers based on the selected market
        // const filteredBrokers = allBrokers
        //     .filter((broker) => broker.market === selectedMarket)
        //     .map((item) => item.broker);

        // setBrokers([...new Set(filteredBrokers)]); // Remove duplicate brokers
    };

    const handleBrokerChange = (selectedBroker) => {
        setBroker(selectedBroker);

        // // Filter brokers based on the selected market
        // const filteredBrokers = allBrokers
        //     .filter((broker) => broker.market === selectedMarket)
        //     .map((item) => item.broker);

        // setBrokers([...new Set(filteredBrokers)]); // Remove duplicate brokers
    };


    // Function to handle button clicks
    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    const validateFields = () => {
        if (!market || !broker || !selectedButton || !symbol || !timeFrame || !direction || !stopLoss || !riskCapitalValue || !optionStrategies || !orderType || !status || !user || !api) {
            setError('All fields are required.');
            return false;
        }
        if (riskCapitalValue < 1000) {
            setError('Risk capital value must be greater than $1000.');
            return false;
        }
        setError(''); // Clear error if validation is successful
        return true;
    };


    // Function to handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!validateFields()) {
            setLoading(false); // Stop loading if validation fails
            return;
        }
        // Create the form data to send
        const formData = {
            broker,
            market,
            selectedApiType: selectedButton,
            symbol,
            timeFrame,
            direction,
            stopLoss,
            riskCapitalValue,
            optionStrategies,
            orderType,
            status,
            swap,
            user: user?.id,
            api: selectedApi
        };

        try {
            const response = await apiClient.post('/analytics/api/create-order/', {
                formData
            });

            const data = await response.data;
            setError(data.message);
            setBroker('');
            setMarket('');
            setSymbol('');
            setTimeFrame('');
            setDirection('');
            setStopLoss('');
            setRiskCapitalValue('');
            console.log('API response:', data);
            handleActiveButtonClick('MyOrders');
        } catch (error) {
            console.error('Error submitting form:', error);
        }
        finally {
            setLoading(false)
        }
    };

    return (
        <div className={`h-[100%] ${selectedFilterfullHeightButton === true ? '' : 'pb-[10%]'} transform transition-all duration-500 ease-out translate-y-full opacity-0 animate-slide-down`}>
            <div className='h-[100%] bg-white rounded-bl-[14px] rounded-br-[14px] p-[50px]'>

                <div className='flex border-b border-gray-500 align-center'>
                    <h1 style={{ fontSize: "25px", fontWeight: "bold", padding: "10px", width: "25%" }} className='pl-2'>Create Order</h1>
                    <p style={{ fontSize: "12px", display: "flex", alignItems: "center", backgroundColor: "#79609F", color: "white", width: "75%", margin: "10px", justifyContent: "center" }}>
                        Your API Key is securely encrypted and will be automatically deleted if your account remains inactive for 2 weeks
                    </p>
                </div>

                <div className='flex align-center h-[calc(100%-2rem)]'>
                    <div style={{ width: "25%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }} className='border-r border-gray-500' >
                        <div style={{ padding: "10px", width: "100%", height: "90%", justifyContent: "flex-start" }} className='flex flex-col align-start overflow-y-auto'>
                            {strategies.length > 0 ? (
                                strategies.map((strategy) => (
                                    <button
                                        key={strategy}
                                        className={`border border-gray-500 p-3 m-2 ${selectedButton === strategy ? 'bg-black text-white' : ''}`}
                                        onClick={() => handleButtonClick(strategy)}
                                    >
                                        {strategy.charAt(0).toUpperCase() + strategy.slice(1)}
                                    </button>
                                ))
                            ) : (
                                null
                            )}
                        </div>
                        <div style={{ padding: "10px", width: "100%", justifyContent: "center", alignItems: "center" }} className='flex flex-col align-start'>
                            <select className='border' style={{ width: "93%", padding: "10px", cursor: "pointer" }}
                                value={status}
                                onChange={(e) => setStaus(e.target.value)}
                            >
                                <option>Active</option>
                                {/* <option>Hold</option>
                                <option>Closed</option> */}
                            </select>
                        </div>
                    </div>


                    <div style={{ fontSize: "12px", width: "75%", margin: "10px" }}>
                        {error && <p className="text-red-500 m-2">{error}</p>}

                        <form className='h-full flex flex-col justify-between items-end' onSubmit={handleSubmit}>
                            <div className='w-full'>

                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={broker}
                                    onChange={(e) => handleBrokerChange(e.target.value)}

                                >
                                    <option>Select Broker</option>
                                    {brokers.map((broker, index) => (
                                        <option key={index} value={broker}>{broker}</option>
                                    ))}
                                </select>
                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={selectedApi}
                                    onChange={(e) => setSelectedApi(e.target.value)}
                                >
                                    <option>Select API</option>
                                    {api.map((a, index) => (
                                        <option key={index} value={a.id}>{a.api_name}</option>
                                    ))}
                                </select>
                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={market}
                                    onChange={(e) => handleMarketChange(e.target.value)}
                                >
                                    <option>Select Market</option>
                                    {markets.map((market, index) => (
                                        <option key={index} value={market}>{market}</option>
                                    ))}
                                </select>

                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={symbol}
                                    onChange={(e) => setSymbol(e.target.value)}
                                >
                                    <option>Select Symbol</option>
                                    {symbols.map((symbol, index) => (
                                        <option key={index} value={symbol}>{symbol}</option>
                                    ))}
                                </select>
                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={timeFrame}
                                    onChange={(e) => setTimeFrame(e.target.value)}
                                >
                                    <option>Select Time Frame</option>
                                    {timeFrames.map((timeFrame, index) => (
                                        <option key={index} value={timeFrame}>{timeFrame}</option>
                                    ))}
                                </select>
                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={direction}
                                    onChange={(e) => setDirection(e.target.value)}
                                >
                                    {/* <option>Long/Short</option> */}
                                    <option selected value="Long">Long</option>
                                    <option value="Short">Short</option>
                                </select>
                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={stopLoss}
                                    onChange={(e) => setStopLoss(e.target.value)}
                                >
                                    <option value='Strategy Stop Loss'>Strategy Stop Loss</option>
                                    {/* {Array.from({ length: 6 }, (_, i) => (
                                        <option key={i} value={10 * (i + 1)}>{10 * (i + 1)}</option>
                                    ))} */}
                                </select>

                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={orderType}
                                    onChange={(e) => setOrderType(e.target.value)}
                                >
                                    <option selected value="Market Order">Market Order</option>
                                    <option value="Limit Order">Limit Order</option>
                                </select>
                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={optionStrategies}
                                    onChange={(e) => setOptionStrategies(e.target.value)}
                                >
                                    <option selected value="Future">Future</option>
                                    <option value="Spot">Spot</option>
                                </select>
                                <select required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    value={swap}
                                    onChange={(e) => setSwap(e.target.value)}
                                >
                                    <option selected value="Normal">Normal</option>
                                    <option value="Swap">Swap</option>
                                </select>
                                <input required
                                    className='border border-gray-500 p-2 m-2 rounded w-[250px]'
                                    placeholder='Enter Risk Capital Value'
                                    value={riskCapitalValue}
                                    onChange={(e) => setRiskCapitalValue(e.target.value)}
                                    min='1000'
                                    type='number'
                                />
                            </div>

                            <div className='flex'>
                                {['Moderate Strategy', 'High Risk Strategy', 'Extreme Risk Strategy'].map((strategy, index) => (
                                    <div key={index} className='w-[30%] p-[20px] border m-2 bg-[#F5F5F5]'>
                                        <span>
                                            <img src={bulbImg} className='w-8' alt="Info" />
                                        </span>
                                        <p>
                                            {strategy === 'Moderate Strategy' && "Moderate Strategy uses 50% capital for lower profits with moderate risk—keep an eye on your portfolio."}
                                            {strategy === 'High Risk Strategy' && "High Risk Strategy uses 100% capital for better profits with high risk. No orders placed without an open position."}
                                            {strategy === 'Extreme Risk Strategy' && "Extreme Risk Strategy uses +200% capital for higher profits with higher risk of margin call—using leverage brings margin calls faster."}
                                        </p>
                                    </div>
                                ))}
                            </div>

                            <button type='submit' className='bg-[#A5F4A3] p-2 rounded w-[125px] m-2'>
                                <div className="flex items-center justify-center">
                                    {loading ? (
                                        <svg
                                            className="w-5 h-5 mr-3 text-white animate-spin"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                    ) : null}
                                    {loading ? "Creating..." : "Create Order"}
                                </div>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateOrder;
