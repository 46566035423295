// Modal.js
import React from "react";

const Modal = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded shadow-md">
                <h2 className="text-lg font-bold mb-4">Confirmation</h2>
                <p>{message}</p>
                <button
                    className="mt-4 p-2 bg-blue-500 text-white rounded"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
            <div className="fixed inset-0 bg-black opacity-30"></div>
        </div>
    );
};

export default Modal;
