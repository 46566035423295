import React, { useState, useEffect } from 'react';
import apiClient from '../../actions/axiosInterceptor';
import Disclaimers from '../../assets/image/information.png';
import Wifi from '../../assets/image/wifi.png';
import WifiDisconected from '../../assets/image/wi-fi-disconnected.png';
import APIMANAGEMENT from '../../assets/image/cloud-coding-svgrepo-com.svg';
import { FiBook } from 'react-icons/fi'

const NavBar = ({ user, accessToken, handleLogout, handleActiveButtonClick, openSidebar }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [result, setResult] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [isMaintenance, setIsMaintenance] = useState(false);  // Maintenance state

    const [formData, setFormData] = useState({
        first_name: userData?.profile?.user.first_name || '',
        last_name: userData?.profile?.user.last_name || '',
        mobile: userData?.profile?.mobile_no || '',
    });

    const [activeButton, setActiveButton] = useState(null);

    const handleButtonClick = (buttonName) => {
        handleActiveButtonClick(buttonName);
        setActiveButton(buttonName);
    };

    const [showDropdown, setShowDropdown] = useState(false);

    // Toggle dropdown visibility
    const handleSettingButtonClick = () => {
        setShowDropdown((prev) => !prev);
    };

    // Handle menu options
    const handleOptionClick = (buttonName) => {
        setActiveButton(buttonName);
        if (buttonName === 'Help') {
            openSidebar();
        }
        else {
            handleActiveButtonClick(buttonName);
        }
        if (buttonName === 'add_api' || buttonName === 'Billings' || buttonName === 'Contacts' || buttonName === 'Help') {
            setShowDropdown((prev) => !prev);
        }

    };

    // Fetch user data if access token is available
    const fetchUserData = async () => {
        try {
            const response = await apiClient.get('/auth/api/me/');
            console.log(response.data)
            setUserData(response.data); // Assuming response contains user data
            setFormData({
                first_name: response?.data.profile.user.first_name || '',
                last_name: response?.data.profile.user.last_name || '',
                mobile: response?.data.profile.mobile_no || '',
            })
        } catch (error) {
            console.error('Error fetching user data:', error);
            if (error.response && error.response.status === 401) {
                handleLogout(); // Log out the user if unauthorized
            }
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [accessToken]); // Add `handleLogout` as a dependency if it's coming from props or state


    const handleReload = () => {
        window.location.reload();
    };

    // Function to toggle fullscreen when the second image is clicked
    const handleFullscreen = () => {
        const doc = document.documentElement;
        if (!document.fullscreenElement) {
            doc.requestFullscreen().catch((err) => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    };

    const [isOnline, setIsOnline] = useState(navigator.onLine); // Initialize state with online status

    useEffect(() => {
        // Function to update the online status
        const updateOnlineStatus = () => {
            setIsOnline(navigator.onLine);
        };

        // Add event listeners to monitor online/offline status changes
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        // Cleanup event listeners when the component unmounts
        return () => {
            window.removeEventListener('online', updateOnlineStatus);
            window.removeEventListener('offline', updateOnlineStatus);
        };
    }, []);

    const handleUserNameClick = () => {
        setShowPopup(true); // Show the popup when name icon is clicked
        setResult('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const closePopup = () => {
        setShowPopup(false); // Close the popup when clicking outside or cancel
    };

    const [isToggled, setIsToggled] = useState(false);

    const handleToggleClick = () => {
        setIsToggled(prevState => !prevState);
    };

    // Fetch maintenance status
    const fetchMaintenanceStatus = async () => {
        try {
            const response = await apiClient.get('/adminestration/api/maintenance-status/');
            setIsMaintenance(response.data.status);
        } catch (error) {
            console.error('Error fetching maintenance status:', error);
        }
    };

    // Toggle maintenance status
    const toggleMaintenanceStatus = async () => {
        try {
            const response = await apiClient.post('/adminestration/api/maintenance-status/');
            setIsMaintenance(response.data.status);
            fetchMaintenanceStatus();
        } catch (error) {
            console.error('Error toggling maintenance status:', error);
        }
    };

    useEffect(() => {
        fetchUserData();
        fetchMaintenanceStatus();  // Check maintenance status on mount
    }, [accessToken]);


    return (
        <nav className="bg-white text-black shadow-md h-12">
            <div className="flex flex-col w-full h-12 max-md:max-w-full">
                <div className="flex flex-wrap items-start w-full h-12 max-md:max-w-full">
                    <div className="flex  flex-col grow shrink min-w-[240px] w-full max-md:max-w-full">
                        <div className="flex  flex-col pb-24 min-h-[142px] max-md:pb-24 max-md:max-w-full">
                            <div className="flex flex-col w-full  max-md:max-w-full">
                                <div className="flex items-start w-full h-12 max-md:max-w-full">
                                    <div className="flex items-start w-full h-12 max-md:max-w-full">
                                        <div className="flex  justify-center items-center bg-white h-12 min-w-12" onClick={handleUserNameClick}>
                                            <div className="bg-orange-500 border border-orange-500 p-2 rounded-full w-8 h-8 flex items-center justify-center text-white cursor-pointer">
                                                {user?.first_name.charAt(0).toUpperCase()}
                                            </div>
                                        </div>
                                        <div className="flex items-center px-1  bg-white h-12 ">
                                            <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                                        </div>
                                        <div className="flex  justify-center items-center bg-white h-12 ">

                                            <div className="flex  justify-center items-center my-auto h-12 cursor-pointer " onClick={() => handleButtonClick('home')}>
                                                <div className="self-stretch pl-0.5 pr-1 my-auto text-sm text-gray-900 whitespace-nowrap">
                                                    Dashboard
                                                </div>

                                            </div>
                                        </div>

                                        <div className="flex items-center px-1  bg-white h-12 ">
                                            <div className="flex self-stretch my-auto w-px bg-zinc-200 min-h-[23px]" />
                                        </div>
                                        <div className="flex  justify-center items-center bg-white h-12 ">

                                            <div className="flex  justify-center items-center my-auto h-12 cursor-pointer " onClick={() => handleButtonClick('push')}>
                                                <div className="self-stretch pl-0.5 pr-1 my-auto text-sm text-gray-900 whitespace-nowrap">
                                                    Push Notifications
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex items-start justify-end w-full h-12 max-md:max-w-full">

                                        <div className="flex items-center self-stretch p-1.5 my-auto h-12">
                                            <div className="flex flex-col self-stretch my-auto cursor-pointer" onClick={handleReload}>
                                                <img
                                                    loading="lazy"
                                                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/700bc696c33df2b45c74c09529ac0968b24b4ea5f41e76be2e183df250b29201?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                                                    className="object-contain aspect-square w-6"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex  justify-center items-center self-stretch my-auto bg-white h-12 px-1.5">
                                            <div className="flex items-center self-stretch pr-1  my-auto h-12">
                                                <div className="flex flex-col self-stretch my-auto  cursor-pointer" onClick={handleFullscreen} >
                                                    <img
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/0355f561f2b8bb78b777bd7fb78332444b799a54151b66afed1d475c11c28d55?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                                                        className="object-contain w-full aspect-square"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex items-center self-stretch px-1.5 my-auto h-12  cursor-pointer" >

                                            <div className="relative z-9999">
                                                <div className="flex items-center self-stretch px-1.5 my-auto h-12 cursor-pointer">
                                                    <img
                                                        onClick={handleSettingButtonClick}
                                                        loading="lazy"
                                                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/2c8746834b4863a7fb7cf8dabbaaacf77c03d71d145aac925a1371a7a0a68014?placeholderIfAbsent=true&apiKey=2a454d8142b847a4b0ac26be6efc2b89"
                                                        className="object-contain self-stretch my-auto aspect-square w-[21px]"
                                                    />
                                                </div>

                                                {/* Dropdown menu */}
                                                {showDropdown && (
                                                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg" style={{ zIndex: "999" }} >
                                                        <ul className="py-1 z-9999">

                                                            <li
                                                                className={`px-4 py-2 text-gray-700 cursor-pointer
                                                                    }`}
                                                            >
                                                                <div onClick={toggleMaintenanceStatus} className="cursor-pointer">
                                                                    {isMaintenance ? 'Disable Maintenance' : 'Enable Maintenance'}
                                                                </div>
                                                            </li>
                                                            <li
                                                                className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-200"
                                                                onClick={() => handleOptionClick('Help')}
                                                            >
                                                                Help
                                                            </li>
                                                            <li
                                                                className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-200 cursor-pointer"
                                                                onClick={handleLogout}
                                                            >
                                                                Log Out
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {isOnline ? (
                                            <img
                                                width="38"
                                                height="38"
                                                className="object-contain shrink-0 self-stretch my-auto px-1.5"
                                                src={Wifi}
                                                alt="wi-fi-connected"
                                            />
                                        ) : (
                                            <img
                                                width="38"
                                                height="38"
                                                className="object-contain shrink-0 self-stretch my-auto px-1.5"
                                                src={WifiDisconected}
                                                alt="wi-fi-disconnected"
                                            />
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </nav>
    );
};

export default NavBar;
