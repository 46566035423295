import React, { useEffect, useRef, memo, useState } from 'react';
import '../../assets/css/Dashboard.css';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import baseURL from './../../url';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Registering the necessary Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Home = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    const [createdOrdersData, setCreatedOrdersData] = useState([]);
    const [openOrdersData, setOpenOrdersData] = useState([]);
    const [executedValueData, setExecutedValueData] = useState([]);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Users Count by Month',
                data: [],
                borderColor: 'rgba(75,192,192,1)',
                fill: false,
            },
        ],
    });

    useEffect(() => {
        axios.get(`${baseURL}/adminestration/api/users/count-by-month/`)
            .then(response => {
                const months = response.data.map(item => `Month ${item.month}`);
                const counts = response.data.map(item => item.count);

                setChartData({
                    labels: months,
                    datasets: [{
                        label: 'Users Count by Month',
                        data: counts,
                        borderColor: 'rgba(75,192,192,1)',
                        fill: false,
                    }],
                });
            })
            .catch(error => {
                console.log(error);
            });
    }, []);


    const fetchData = async () => {
        try {
            // Fetch created orders by month
            const createdOrdersResponse = await axios.get(`${baseURL}/adminestration/api/orders/created-orders-by-month/`);
            setCreatedOrdersData(createdOrdersResponse.data);

            // Fetch open orders by month
            const openOrdersResponse = await axios.get(`${baseURL}/adminestration/api/orders/open-orders-by-month/`);
            setOpenOrdersData(openOrdersResponse.data);

            // Fetch executed value by month
            const executedValueResponse = await axios.get(`${baseURL}/adminestration/api/orders/executed-value-by-month/`);
            setExecutedValueData(executedValueResponse.data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatDataForChart = (data) => {
        const months = data.map(item => `Month ${item.month}`);
        const values = data.map(item => item.order_count || item.executed_value);
        return { labels: months, datasets: [{ label: 'Orders', data: values, borderColor: 'rgba(75,192,192,1)', fill: false }] };
    };

    return (
        <div className="dashboard-container overflow-scroll">
            <div className="chart-container">
                <h1>Users Count by Month</h1>
                <Line data={chartData} />
            </div>
            <div className="chart-container">
                <h1>Created Orders by Month</h1>
                <Line data={formatDataForChart(createdOrdersData)} />
            </div>
            <div className="chart-container">
                <h1>Open Orders by Month</h1>
                <Line data={formatDataForChart(openOrdersData)} />
            </div>
            <div className="chart-container">
                <h1>Executed Value by Month</h1>
                <Line data={formatDataForChart(executedValueData)} />
            </div>
        </div>
    );
};

export default memo(Home);
