import React from 'react';
import '../assets/css/Dashboard.css'; // Assuming custom styles are already here

const Disclaimer = ({ user, accessToken, handleLogout, selectedFilterfullHeightButton }) => {
    return (
        <div className={`h-full w-full p-6 bg-white rounded-xl shadow-lg ${selectedFilterfullHeightButton ? 'visible' : ''} transform transition-all duration-500 ease-out`}>
            <div className=" h-full w-full rounded-lg p-8 overflow-auto">
                <div className="max-w-5xl mx-auto my-6">
                    <h1 className="text-4xl font-bold text-gray-800 mb-8 text-center leading-tight">
                        Disclaimer for <span className="text-indigo-600">Hedge+</span> by <span className="text-indigo-600">HedgeMarc</span>
                    </h1>

                    <p className="text-gray-700 mb-6 text-lg leading-relaxed">
                        Hedge+ is a tool developed and managed by HedgeMarc for internal use within the community, created and maintained by individuals. By using Hedge+, you agree to the following terms and conditions:
                    </p>

                    <h2 className="text-3xl font-semibold text-gray-800 mb-4">Terms & Conditions</h2>

                    <ol className="list-decimal list-inside text-gray-700 space-y-6 text-m leading-relaxed">
                        <li><strong>Trading Involves Risk:</strong> Engaging in financial markets—including stocks, forex, cryptocurrencies, and other derivatives—comes with significant risks. The value of your investments can fluctuate, and you could lose part or all of your capital. It’s essential to understand these risks and assess your risk tolerance before trading. Please note that past performance does not guarantee future results.</li>

                        <li><strong>No Financial Advice:</strong> The tools, strategies, and information provided on Hedge+ are for informational purposes only and do not constitute financial, legal, or investment advice. You are fully responsible for your own trading decisions. We encourage you to consult a financial advisor if you’re uncertain about your strategy.</li>

                        <li><strong>Risks of Automated Trading:</strong> While automated trading systems can execute trades based on preset rules, they may not capture real-time market conditions. Factors like market volatility, low liquidity, and data delays can lead to unexpected outcomes. We are not liable for any losses resulting from trades executed by the automated system.</li>

                        <li><strong>Platform Availability:</strong> We strive to offer uninterrupted access to Hedge+, but technical issues, maintenance, or other unforeseen events may cause temporary outages. We do not guarantee continuous access and cannot be held responsible for any losses due to platform downtime.</li>

                        <li><strong>Accuracy of Information:</strong> Data, signals, and other information provided by Hedge+ may not always be up-to-date, accurate, or complete. We encourage you to verify the information independently before making any trading decisions.</li>

                        <li><strong>Legal Compliance:</strong> You are responsible for ensuring that your use of Hedge+ complies with all applicable laws and regulations in your jurisdiction. This includes, but is not limited to, securities regulations and tax laws. Consult legal experts as needed.</li>

                        <li><strong>No Profit Guarantees:</strong> We make no promises that Hedge+ will result in profits or minimize losses. You use this tool at your own risk, and we disclaim all warranties, including those related to accuracy, reliability, or fitness for a specific purpose.</li>

                        <li><strong>Limitation of Liability:</strong> To the fullest extent allowed by law, we are not liable for any direct, indirect, or incidental losses or damages resulting from the use of Hedge+. Our total liability, if any, is limited to the fees paid for the service.</li>

                        <li><strong>Service Modifications:</strong> We reserve the right to modify, suspend, or discontinue any aspect of Hedge+ at any time without notice. Continued use after changes implies your acceptance of the revised terms.</li>

                        <li><strong>User Responsibility:</strong> You are ultimately responsible for the outcome of all trading decisions made via Hedge+, whether manual or automated. We recommend regular monitoring of your trading activities to ensure they align with your financial goals.</li>
                    </ol>

                    <h2 className="text-3xl font-semibold text-gray-800 mt-10 mb-4">Security</h2>

                    <ol className="list-decimal list-inside text-gray-700 space-y-6 text-m leading-relaxed">
                        <li><strong>Broker API Integration:</strong> By connecting your broker’s API to Hedge+, you authorize our platform to automate trades on your behalf based on the trading rules you have set.</li>

                        <li><strong>Secure Data Handling:</strong> We prioritize the security of your broker API credentials. All API keys and sensitive information are encrypted and stored securely, ensuring that unauthorized parties cannot access your data.</li>

                        <li><strong>No Data Sharing:</strong> Your broker API details and trading activity are kept private. We do not share, sell, or disclose your data to third parties.</li>

                        <li><strong>User Responsibility for API Keys:</strong> You are responsible for safeguarding your API keys and ensuring their correct setup. Any misuse or unauthorized access resulting from improper management of your credentials is your responsibility.</li>

                        <li><strong>Encrypted Transactions:</strong> All trade execution and communication between Hedge+ and your broker’s API are encrypted, providing an additional layer of security to prevent data breaches and unauthorized transactions.</li>
                    </ol>

                    <p className="text-gray-700 mt-8 text-lg leading-relaxed">
                        By using Hedge+, you acknowledge that you have read, understood, and agree to these terms and conditions.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Disclaimer;
