import React, { useEffect } from "react";

// Helper function to convert VAPID public key to Uint8Array
function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
    const rawData = window.atob(base64);
    return new Uint8Array([...rawData].map(char => char.charCodeAt(0)));
}

const publicVapidKey = "BK5sFUOlWhJ-82u0pbyARr-RsBh3rMDtR4HfuR2Bb5uGf757M-ZI8oDDuD72dJ4qwoB7A2A_YgOdPu_VRVnM4MU"; // Replace with your VAPID public key

const PushNotification = ({ message }) => {
    useEffect(() => {
        const registerServiceWorker = async () => {
            if ("serviceWorker" in navigator && "PushManager" in window) {
                try {
                    const registration = await navigator.serviceWorker.register("/service-worker.js");
                    console.log("Service Worker registered");

                    const permission = await Notification.requestPermission();
                    if (permission === "granted") {
                        console.log("Notification permission granted.");

                        const subscription = await registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
                        });

                        // Optional: Send subscription to your backend
                        // await sendSubscriptionToServer(subscription);

                        if (message) {
                            triggerPushNotification(message);
                        }
                    } else {
                        console.warn("Notification permission denied");
                    }
                } catch (error) {
                    console.error("Error during service worker registration or subscription:", error);
                    if (error.name === "AbortError") {
                        console.error("Push service registration failed: abort error");
                    }
                    if (error.name === "InvalidStateError") {
                        console.error("Invalid state during service worker registration");
                    }
                }
            } else {
                console.warn("Push messaging is not supported in this browser.");
            }
        };

        registerServiceWorker();
    }, [message]);

    const sendSubscriptionToServer = async (subscription) => {
        try {
            const response = await fetch("/save-subscription/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(subscription),
            });
            const data = await response.json();
            console.log("Subscription saved:", data.message);
        } catch (error) {
            console.error("Error saving subscription:", error);
        }
    };

    const triggerPushNotification = (message) => {
        console.log("Triggering local push notification:", message);
        new Notification("New Notification", { body: message });
    };

    return null;
};

export default PushNotification;
