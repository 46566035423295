import React, { useState } from "react";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import apiClient from '../actions/axiosInterceptor';
import Modal from './Modal'; // Import the Modal component

const PayPalCheckoutButton = ({ amount, selectedButton, setShowPayPal }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [showModal, setShowModal] = useState(false);

    // Create Order Function
    const createOrder = async (data, actions) => {
        try {
            setLoading(true);
            // Call your backend to create the PayPal order
            const res = await apiClient.post("/auth/api/paypal/create/", {
                amount: amount,
                plan: selectedButton
                // Send amount to the backend
            });
            // If the response contains the order ID, return it to PayPal
            return res.data.orderID; // Make sure your backend returns orderID properly
        } catch (error) {
            console.error("Payment creation failed", error);
            setError("Payment creation failed. Please try again.");
            setLoading(false); // Stop loading on error
            return actions.reject(); // Reject the order if there was an error
        } finally {
            setLoading(false); // End loading regardless of success or error
        }
    };

    // On Approve function after payment success
    const onApprove = async (data, actions) => {
        try {
            const details = await actions.order.capture();
            console.log(details);
            const response = await apiClient.post(`/auth/api/active/verify/`, {
                details
            });
            // window.location.reload();
            // alert(`Transaction completed by ${details.payer.name.given_name}`);
            setConfirmationMessage(`Transaction completed by ${details.payer.name.given_name}. Thank you for your payment!`);
            setShowModal(true); // Show the modal
        } catch (error) {
            console.error("Payment capture failed", error);
            setError("Payment capture failed. Please try again.");
        }
        finally {
            setShowPayPal(false)
        }
    };

    const onCloseModal = () => {
        setShowModal(false); // Close the modal
        window.location.reload(); // Reload the page or navigate as needed
    };

    // Error handling for PayPal errors
    const onError = (error) => {
        setShowPayPal(false)
        console.error("Payment failed: ", error);
        setError("Payment failed. Please try again.");
    };

    // When the user cancels the payment
    const onCancel = () => {
        setShowPayPal(false)
        alert("Payment canceled by the user.");
    };

    return (
        <PayPalScriptProvider
            options={{
                "client-id": "AXmn0ys8TdwydlIrLeXNYJVKGaxOe8S5DkrgkJdVDJ1iN4R04HJN7oN3WpLC5QbPzNFn3prgmapkfDxZ", // Replace with actual client ID
                currency: "USD",
            }}
        >
            <div className="flex justify-center w-full" style={{ flexDirection: "column" }} >
                {loading && <p>Loading...</p>}
                {error && <p style={{ color: "red" }}>{error}</p>}

                <PayPalButtons
                    style={{ layout: "vertical" }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                    onCancel={onCancel}
                />
                {showModal && (
                    <Modal message={confirmationMessage} onClose={onCloseModal} />
                )}

            </div>
        </PayPalScriptProvider>
    );
};

export default PayPalCheckoutButton;
